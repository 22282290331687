<template>
    <div class="service_container">
        <h2>Periodontologia</h2>
        <p>Większość chorób przyzębia jak zapalenie dziąseł i zapalenie tkanek otaczających ząb to efekt niewłaściwej higieny jamy ustnej i nieprawidłowej diety.</p>
        <p>Stomatolog zaczyna leczenie od oceny problemu i higienizacji, czyli usunięcia kamienia, piaskowania i fluoryzacji. 
          Doradza, jak prawidłowo dbać o higienę jamy ustnej. Jeśli stan nie ulega poprawi albo choroba jest bardzo zaawansowana, 
          kieruje pacjenta na zabieg chirurgiczny, który pozwala zregenerować kość i okoliczne tkanki.</p>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>