<template>
    <div class="service_container">
        <h2>Protetyka</h2>
        <p>Protetyka to dziedzina stomatologii zajmująca odtwarzaniem braków w uzębieniu we współpracy z technikiem dentystycznym, 
          gdy zęby własne zostały utracone lub mocno uszkodzone.</p>
        <p>Konsekwencją nieuzupełnionych braków zębowych jest przesuwanie się zębów sąsiednich, 
          braki mogą prowadzić także do utraty zębów własnych. Powodują również problemy z układem pokarmowym, 
          gdyż możliwość żucia pokarmu jest ograniczona, a twarz wygląda starzej.</p>
        <p>W ramach leczenia protetycznego proponujemy pacjentom rozwiązania dopasowane do ich potrzeb:</p>
        <ul>
          <li>Licówki - cienkie, porcelanowe płatki przyklejane do powierzchni zęba, aby poprawić kształt, kolor, zniwelować przebarwienia lub lukę między górnymi jedynkami, czyli diastemę.</li>
          <li>Korony - zakładane na dość mocno uszkodzone przez próchnicę zęby lub zęby złamane.</li>
          <li>Mosty - stosowane, gdy trzeba uzupełnić brak jednego lub więcej zębów - mocowane na zębach sąsiednich.</li>
          <li>Protezy Premium - szkieletowe, akrylowe - pozwalające uzupełnić większe braki w uzębieniu, wykonywane we współpracy z technikiem dentystycznym w sposób idealnie odwzorowujący naturę.</li>
        </ul>
        <p>Nasze prace są wykonywane cyfrowo, przy użyciu skanera wewnątrzustnego (bez potrzeby pobierania nieprzyjemnych wycisków) w technologii CAD/CAM.</p>
        <h3>Cyfrowe projektowanie uśmiechu DSD</h3>
        <p>DSD- Digital Smile Design to inaczej cyfrowe projektowanie uśmiechu.
        Pozwala pacjentowi zobaczyć końcowy efekt leczenia, zanim jeszcze podejmie decyzję o rozpoczęciu leczenia.</p>
        <p>Na podstawie zdjęć zębów i twarzy pacjenta, dzięki użyciu specjalnej technologii i algorytmów lekarz dentysta rozpoczyna proces projektowania, 
          następnie pobiera skan wewnątrzustny i dzięki współpracy z technikiem przygotowuje prototyp nowego uśmiechu, który następnie można przymierzyć w ustach.</p>
        <p>Uzupełnienia protetyczne stworzone w protokole DSD idealnie pasują do rysów twarzy pacjenta.</p>
        <video controls>
          <source :src="require('../../assets/videos/protetyka.mp4')" @load="test" type="video/mp4">
        Twoja przeglądarka nie obsługuje video.
        </video>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>