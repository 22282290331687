<template>
    <div class="service_container">
        <h2>DIAGNOSTYKA</h2>
        <p>Proces leczenia stomatologicznego wymaga szczegółowego planu. Współpracę z pacjentami zaczynamy od pełnej diagnostyki jamy ustnej. 
          Polega na wykonaniu tomografii komputerowej 3D (CBCT), modeli diagnostycznych przy użyciu skanu wewnątrzustnego oraz dokumentacji fotograficznej. 
          Na podstawie wyników badań i rozmowy lekarz prowadzący opracowuje jasny i zrozumiały dla pacjenta plan leczenia.
          Dzięki temu możemy leczyć szybciej i skuteczniej.
        </p>
        <p>Wykonujemy na miejscu wszystkie rodzaje zdjęć rentgenowskich: zdjęcia punktowe, pantomogram, 
          zdjęcie cefalometryczne(zdjęcie czaszki w pozycji bocznej), tomografię 3D szczęki i żuchwy, oraz tomografię zatok.
        </p>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>