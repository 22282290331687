<template>
    <transition
        @enter="enterR"
    >
        <div v-if="loadContent" id="content">
            <div class="name">
                <h1>STOMEDICA</h1>
                <h2>Waszczuk-Dziekan</h2>
            </div>
        </div>
    </transition>
</template>
<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default {
    props: {
        loadContent: {
            type: Boolean,
            required: true
        }
    },
    mounted() {
    },
    methods: {
        enterR() {
            gsap.fromTo('#content', { x: -200, opacity: 0}, { x: 0, opacity: 1, duration: 0.5})
        },
    }
}
</script>

<style lang="scss" scoped>
#content {
    grid-column: 1 / 4;
    grid-row: 2;
    display: flex;
    .name {
        align-self: flex-end;
        text-align: center;
        text-transform: uppercase;
        padding: 0 0 20vh 3vw;
        h1 {
            margin-bottom: 5px;
            // font-size: 58px;
        }
        h2 {
            margin-top: 4px;
            color: #eee;
            letter-spacing: 1px;
            font-size: 21px !important;
        }
    }
}
</style>