<template>
    <div class="service_container">
        <h2>Stomatologia dziecięca</h2>
        <p>Leczenie stomatologiczne dzieci często związane jest dla nich z dużym stresem, 
          dlatego zapraszamy na wizyty adaptacyjne, podczas których dzieci będą mogły zapoznać się z miejscem oraz personelem, 
          a rodzic otrzyma poradnik jak przygotować dziecko do wizyty.Przed leczeniem dzieci będą mogły poznać gabinet oraz sprzęt, a także poznać otoczenie.</p>
        <p>Umożliwiamy zabiegi przy użyciu gazu rozweselającego, aby zniwelować stres związany z wizytą. Posiadamy również kolorowe wypełnienia, 
          które mali Pacjenci uwielbiają!</p>
        <p>Wykonujemy także zabiegi profilaktyczne - pakiet higienizacyjny Junior, instruktaż higieny, fluoryzację i lakowanie bruzd.</p>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>