<template>
  <div ref="contact" id="contact_section" class="container">
    <div class="content">
      <div class="flex_box">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2334.9632819938793!2d15.59428541600057!3d54.180726119585394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47003fc35671126d%3A0xdf8a5df67c770f14!2sStomedica%20Waszczuk-Dziekan!5e0!3m2!1spl!2spl!4v1636515324313!5m2!1spl!2spl" width="100%" height="450" style="border:0;" title="localization" allowfullscreen="" loading="lazy"></iframe>
        <div class="contact">
          <h2>Kontakt</h2>
          <p><b>ul. Żółkiewskiego 1A</b>, wejście od strony lokali użytkowych.</p>
          <p>Kołobrzeg</p>
          <a href="tel:510-234-111">Telefon: <b>+48 510 234 111</b></a>
          <p>E-mail: <b>recepcja@stomedica.pl</b></p>
        </div>
        <div class="open">
          <h2>Godziny otwarcia</h2>
          <p>Poniedziałek-Piątek: 09:00–20:00</p>
          <p>Sobota: nieczynne</p>
        </div>
      </div>
      <footer>
        <p class="pp" @click="isOpenModal=!isOpenModal">Polityka prywatności</p>
        <p>Stomedica © 2021 All rights reserved.</p>
      </footer>
    </div>
    <pop-up id="popup" v-if="isOpenModal" @close="close()" :additionals="false">
        <privacy-policy />
    </pop-up>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PopUp from '../PopUp'
import PrivacyPolicy from '../PrivacyPolicy'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Contact',
  props: {
  },
  components: {
    PopUp,
    PrivacyPolicy
  },
  data: function () {
    return {
      isOpenModal: false,
    }
  },
  mounted() {
      const container = document.getElementById('contact_section')
      gsap.fromTo(container.children, {x: '-=100', opacity: 0}, {x: 0, opacity: 1, stagger: 1.2, duration: 1})
  },
  methods: {
    close() {
      this.isOpenModal = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
a {
  text-decoration: none;
  color: rgb(83, 82, 82);
}
.container {
    padding-top: 5%;
    width: 100%;
    position: relative;
    background-color: $contact;
    color: --color-bg;
    display: flex;
    justify-content: center;
    .content {
      max-width: 1500px;
      width: 100%;
      .flex_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 823px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        p {
          color: rgb(83, 82, 82);
        }
        h1 {
            font-size: 64px;
            color: var(--color-bg);
        }
        iframe {
            max-width: 1500px;
        }
        .contact {
          padding: 20px 30px;
        }
      }
      footer > p {
        margin-top: 10vh;
        text-align: center;
      }
    }
  &:before {
    content: '';
    width: 100%;
    height: 50px;
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
  }
}
.pp {
  cursor: pointer;
  max-width: 300px;
  margin: 0 auto;
}
</style>
