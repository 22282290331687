<template>
  <div ref="clinic" id="clinic_section" class="container">
    <vue-picture-swipe ref="pictureSwipe" :items="[]"></vue-picture-swipe>
    <div class="top">
          <h1>Wyposażenie</h1>
          <div @click="openGallery()" class="btn">Otwórz Galerie</div>
    </div>
    <img id="img1" class="imageBg" :src="activeImg.img">
    <img id="img2" class="imageBg1" :src="activeImg.img">
    <div id="tool_content">
        <h2 class="tool_name">{{ activeImg.name }}</h2>
        <p class="tool_text">{{ activeImg.text }}</p>
    </div>
    <TransitionGroup tag="ul" name="fade" class="container_group">
    <div v-for="item in displayItems" class="item" :key="item">
        <p></p>
        <img @click="remove(item)" class="image" :src="item.img">
    </div>
  </TransitionGroup>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import PhotoSwipe from 'photoswipe/dist/photoswipe'
import VuePictureSwipe from 'vue-picture-swipe'
import * as DefaultPhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
export default {
    components: {
    VuePictureSwipe
  },
    name: 'Clinic',
    data: function () {
        return {
            images: [],
            loaded: false,
            items: [
            {img: require("../../assets/photos/14.jpg"), name: 'Mikroskop stomatologiczny Leica', text: 'Umożliwia nawet 40-krotne powiększenie pola zabiegowego pokazując wszystkie mikrostruktury i szczegóły, które gołym okiem są niewidoczne. Jest niezastąpiony podczas leczenia kanałowego, zabiegów mikrochirurgicznych czy leczeniu zachowawczym.'},
            {img: require("../../assets/photos/10.jpg"), name: 'Tomografia Orthophos SL 3D', text: 'Dzięki tomografii 3D nasi lekarze mogą działać szybciej i skuteczniej, dokładnie diagnozując pacjenta w oparciu o trójwymiarowy model szczęk otrzymany podczas badania. Duży obszar działania przy obniżonej minimalnej dawce promieniowania umożliwia nam trójwymiarową i precyzyjną diagnostykę chorób i struktur, które są niewidoczne gołym okiem.'},
            {img: require("../../assets/photos/9.jpg"), name: 'Unity stomatologiczne Sirona Sinius', text: 'Niezwykle wygodne i dostosowujące się do kręgosłupa Pacjenta fotele z podparciem lędźwiowym zapewniają komfort podczas zabiegów w gabinecie.'},
            ],
            displayItems: [
            {img: require("../../assets/photos/31.jpg"), name: 'Sedacja Masterflux Plus', text: 'Sedacja, czyli inaczej mówiąc gaz rozweselający jest idealnym rozwiązaniem dla młodych pacjentów jak i dorosłych. Pomaga pokonać stres, który może występować podczas wizyty. Działa na zasadzie inhalacji, która podawana jest podczas zabiegu.'},
            {img: require("../../assets/photos/13.jpg"), name: 'Airflow Prophylaxis Master', text: 'AIRFLOW® Prophylaxis Master to najnowsze urządzenie do kompleksowej higienizacji w gabinecie. W oparciu o protokół GBT ( Guided Biofilm Therapy ) nasze higienistki oczyszczą Twoje zęby w niezwykle delikatny i przyjemny sposób. Urządzenie posiada również podgrzewaną wodę zapobiegając nadwrażliwości towarzyszącej tradycyjnym zabiegą higienizacji.'},
            {img: require("../../assets/photos/21.jpg"), name: 'Kamera wewnątrzustna', text: 'Duże powiększenie sprawia, że dzięki kamerze lekarz jest w stanie diagnozować najmniejsze zmiany w obrębie zębów i dziąseł. Kamera znacznie ułatwia komunikację z Pacjentem. Na podstawie powiększonego obrazu uzębienia uzyskanego na monitorze komputera lekarz jest w stanie szczegółowo wyjaśnić Pacjentowi źródło problemu z uzębieniem i zaproponować odpowiednie leczenie.'},
            ],
            activeImg: {img: require("../../assets/photos/30.jpg"), name: 'Skaner Wewnątrzustny Primescan', text: 'Szybki i niezwykle precyzyjny skaner, który zastępuje wyciski przez tworzenie wirtualnej kopii zębów. Analizuje nawet do 1 000 000 punktów na sekundę przez co swoją dokładnością prześciga konkurencję i umożliwia tworzenie prac protetycznych takich jak korony lub mosty z precyzją z mikronową precyzją.'},
            activeClick: true
        }
    },
    methods: {
        remove(item) {
            if (!this.activeClick) return
            this.activeClick = false
            const removedImg = item
            const i = this.displayItems.indexOf(item)
            if (i > -1) {
                this.displayItems.splice(i, 1)
            }
            // insert img from bg to list
            this.items.push(this.activeImg)

            // insert img to display list
            this.displayItems.unshift(this.items.shift())

            const img_element = document.getElementById('img2')
            img_element.setAttribute("src", removedImg.img)
            gsap.fromTo(img_element, {scale: 0}, {scale: 1, duration: 0.5, delay: 0.2, ease: 'easeInOut', onComplete: () => {
                const swap_element = document.getElementById('img1')
                swap_element.setAttribute("src", removedImg.img)
                this.activeClick = true

                // replace new activeIMG
                this.activeImg = removedImg
            }})

            const content = document.getElementById('tool_content')
            gsap.to(content, {y: 50, opacity: 0, duration: 0.25, delay: 0.45, ease: 'easeInOut', onComplete: () => {
                gsap.to(content, {y: 0, opacity: 1, duration: 0.25, ease: 'easeInOut'})
            }})
         },
         loadImages() {
            for(let i=1;i<=40;i++) {
                if (i <= 27)
                this.images.push({
                    src: require(`../../assets/photos/${i}.jpg`),
                    w: 2048,
                    h: 1365,
                    alt: 'clinic photo' // optional alt attribute for thumbnail image
                })
                else
                this.images.push({
                    src: require(`../../assets/photos/${i}.jpg`),
                    w: 1365,
                    h: 2048,
                    alt: 'clinic photo' // optional alt attribute for thumbnail image
                })
            }
            this.loaded = true
        },
        openGallery() {
            if(!this.loaded)
                this.loadImages()
            const pswpElement = document.querySelectorAll('.pswp')[0];
            pswpElement.style = "z-index: 9999999;"
            const gallery = new PhotoSwipe(pswpElement, DefaultPhotoSwipeUI, this.images,
                {
                    captionEl: false,
                    shareEl: false,
                });
            gallery.init();
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: rgba(66, 50, 50, 0.8);
    position: relative;
    h1, h2 {
      color: $h1;
    }
    p {
      color: rgb(238, 236, 236);
    }
    .top {
        width: 80%;
        position: absolute;
        z-index: 3;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-left: 120px;
        @media (max-width: 945px) {
            margin-left: 20px;
        }
        .btn {
            color: $h2;
            padding: 20px;
            background-color: $h1;
            border-radius: 20px;
            z-index: 11;
            max-width: 150px;
            text-align: center;
            &:hover {
                cursor: pointer;
            }
        }
        h1 {
            font-size: 64px;
            @media (max-width: 1023px) {
                font-size: 42px;
            }
        }
    }
    .imageBg {
        position: absolute;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        filter: brightness(0.5);
        z-index: 0;
    }
    .imageBg1 {
        position: absolute;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        filter: brightness(0.5);
        z-index: 1;
    }
    #tool_content {
        display: flex;
        flex-direction: column;
        width: 500px;
        z-index: 2;
        justify-content: center;
        margin-left: 120px;
        @media (max-width: 945px) {
            margin-left: 20px;
        }
        .tool_name {
            color: $h1;
        }

        .tool_text {
            letter-spacing: 1px;
            color: whitesmoke;
    }
    }




    .container_group {
        // width: 100%;
        // height: 100vh;
        display: flex;
        flex-direction: row-reverse;
        position: absolute;
        padding: 0;
        bottom: 40vh;
        gap: 10px;
        left: 70%;
        z-index: 2;
        @media (max-width: 945px) {
            bottom: 45px;
            left: 50%;
            transform: translateX(-50%);
        }
        .item {
            width: 7vw;
            min-width: 100px;
            height: 25vh;
            box-sizing: border-box;
            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 12px;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        /* 1. declare transition */
        .fade-move,
        .fade-enter-active,
        .fade-leave-active {
            transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
        }

        /* 2. declare enter from and leave to state */
        .fade-enter-from {
            transform: translateX(20vw);
        }
        .fade-leave-to {
            opacity: 0;
            transform: translateX(-30vw);// scaleX(0.1) ;
        }

        /* 3. ensure leaving items are taken out of layout flow so that moving
            animations can be calculated correctly. */
        .fade-leave-active {
            position: absolute;
        }
    }
}
</style>