<template>
    <div @click="close()" class="blured_wrapper" />
    <div class="content_container">
        <div class="wrapper">
            <img class="wave" src="../assets/wave.svg" alt="logo">
            <img class="wave" src="../assets/wave.svg" alt="logo">
        </div>
        <div class="top_row">
            <div @click="close()" class="close_icon">X</div>
        </div>
        <slot></slot>
    </div>
    <div v-if="additionals" class="navigationA">
        <div class="icon" @click="prev()"><i class="fas fa-arrow-left" /></div>
        <div class="icon" @click="next()"><i class="fas fa-arrow-right" /></div>
    </div>
</template>

<script>

export default {
  props: {
    additionals: {
        type: Boolean,
        default: true
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
        this.$emit('prev')
    },
    next() {
        this.$emit('next')
    },
    close() {
        this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
    &:hover {
        cursor: pointer;
    }
}
.blured_wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 99998;
    background-color: #55555580;
    backdrop-filter: blur(12px);
    top: 0;
    left: 0;
}
.navigationA {
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    width: 96vw;
    max-width: 980px;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

}
.content_container {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    max-width: 1024px;
    background-color: #fff;//#eff;
    padding: 0 30px;
    box-sizing: border-box;
    z-index: 99999;
    overflow-x: hidden;
    overflow-y: auto;

    .wrapper {
        position: absolute;
        width: 100%;
        top: 70px;
        left: -10px;
        z-index: -1;
        .wave {
            display: block;
            width: 110%;
            height: 100%;
            z-index: -3;
            &:nth-child(2) {
                position: absolute;
                top: 80px;
                left: 0;
            }
        }
        &:before {
            content: '';
            display: block;
            background-color: white;
            z-index: 99;
            position: absolute;
            width: 110%;
            height: 80vh;
            top: 0;
            left: 0;
            clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
            background-color: white;
            transition: clip-path 275ms ease;
            animation: discover 1s ease-in 1;
        }
    }
    @keyframes discover {
        to { clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%); }
        from { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
    }

    .bottom {
        flex-grow: 1;
    }

    .service_container {
        min-height: 90vh;
    }

    .top_row {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        .close_icon {
            float: right;
            font-size: 24px;
            padding: 7px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
</style>