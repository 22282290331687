<template>
    <div class="service_container">
        <h2>Implantologia</h2>
        <p>Jest to dziedzina stomatologii, która pozwala uzupełnić braki zębowe w najtrwalszy i najskuteczniejszy sposób odwzorowują naturę i struktury własnego zęba.</p>
        <p>Implanty zastępują brakujące korzenie zębów i są dobrze tolerowane przez organizm
        Zabiegi implantologiczne wykonujemy w bezbolesnych warunkach, używając implantów sprawdzonych producentów z dożywotnią gwarancją, przy użyciu nowoczesnego sprzętu. 
        Jest to procedura bardzo bezpieczna dla Pacjenta.</p>
        <p>Implantolog na konsultacji przeprowadza wywiad z pacjentem i wykonuje potrzebne badania. 
          Po zakwalifikowaniu pacjenta do zabiegu lekarz wszczepia implanty w odpowiednim podłożu, a po 3-6 miesiącach, 
          gdy implant jest dobrze zintegrowany z kością, lekarz dentysta odbudowuje zęby poprzez leczenie protetyczne (korony, mosty, protezy).</p>
        <p>Implanty to doskonałe rozwiązanie uzupełnienia pojedynczego braku zęba, wielu braków zębowych, a także całkowitego bezzębia.</p>
        <p>Zabieg wykonujemy w oparciu o dokładne planowanie przy pomocy tomografii 3D, 
          dzięki czemu jesteśmy w stanie bardzo dobrze ocenić warunki anatomiczne. Wykonujemy także cyfrowe szablony implantologiczne, 
          dzięki czemu zapewniamy naszym Pacjentom bezpieczny i przewidywalny rezultat leczenia.</p> 
        <video controls>
          <source :src="require('../../assets/videos/implantologia.mp4')" @load="test" type="video/mp4">
        Twoja przeglądarka nie obsługuje video.
        </video>              
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>