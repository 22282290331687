<template>
  <div ref="team" id="team_section" class="container">
    <h1>Nasz zespół</h1>
    <div class="cards">
      <Card
        fullName="Laura Waszczuk-Dziekan"
        photoPath="laura_waszczuk_dziekan.jpg"
        profesion="lekarz dentysta"
        :description="laura_text"
        :back="true"
      />
      <Card
        fullName="Mateusz Dziekan"
        photoPath="mateusz_dziekan.jpg"
        profesion="lekarz dentysta"
        :description="mati_text"
        :back="true"
      />
      <Card
        fullName="Yurii Obukhov"
        photoPath="jurij_obukow.jpg"
        profesion="lekarz dentysta"
        :description="jurij_text"
        :back="true"
      />
      <Card
        fullName="Edyta Wącior"
        photoPath="edyta_wacior.jpg"
        profesion="rejestratorka"
        description="do uzupełnienia"
      />
      <Card
        fullName="Aleksandra Sikora"
        photoPath="aleksandra_sikora.jpg"
        profesion="rejestratorka"
        description="do uzupełnienia"
      />
      <Card
        fullName="Julia Wnuk"
        photoPath="julia_wnuk.jpg"
        profesion="higienistka"
        description="do uzupełnienia"
      />
      <Card
        fullName="Aleksandra Malarewicz"
        photoPath="aleksandra_malarewicz.jpg"
        profesion="asystentka"
        description="do uzupełnienia"
      />
      <Card
        fullName="Klaudia Skobiejko"
        photoPath="klaudia_skobiejko.jpg"
        profesion="asystentka"
        description="do uzupełnienia"
      />
      <Card
        fullName="Artur Waszczuk"
        photoPath="artur_waszczuk.jpg"
        profesion="manager"
        description="do uzupełnienia"
      />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Card from './Card.vue'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Team',
  components: {
    Card
  },
  props: {
  },
  data: function () {
    return {
      laura_text: `Ukończyła studia w 2017 roku i od początku wiedziała, że stomatologia to jej życiowa pasja.
        Nieustannie rozwija się w kierunku stomatologii estetycznej oraz stomatologii cyfrowej, endodoncji (leczenia kanałowego), a także ortodoncji cyfrowej. Zajmuje się także leczeniem zachowawczym.
        Dr Laura uczestniczy w wielu kursach i szkoleniach pod okiem doświadczonych specjalistów, które pozwalają na rozwijanie umiejętności oraz są dla niej źródłem inspiracji. Zawsze stara się przedstawić pacjentowi kompleksowy plan działania, aby przywrócić piękny i zdrowy uśmiech.
        Perfekcjonizm, dokładność, precyzja i poczucie humoru to cechy charakteru, które towarzyszą jej w pracy i w życiu prywatnym.
        Prywatnie dr Laurą lubi otaczać się pięknem - sztuką, dobrym filmem i książkami. Uwielbia także podróże, które są dla niej źródłem inspiracji.`,
      mati_text: `W roku 2014 ukończył studia i przez okres 7 lat zdobywał cenne doświadczenie w wiodących w Polsce klinikach stomatologicznych.
        Uczestniczy nieustannie w szkoleniach, konferencjach oraz kursach, które podnoszą jego kompetencje w stosowaniu najnowocześniejszych metod leczenia.
        Dr Mateusz zajmuje się chirurgią stomatologiczną, implantologią, protetyką oraz stomatologią zachowawczą.
        Spokój, subtelność, dokładność i poczucie humoru to cechy, które towarzyszą mu w życiu codziennym jak i w pracy.
        Prywatnie dr Mateusza fascynują nowe cyfrowe technologie zarówno w medycynie jak i codziennym życiu, jego pasją jest także gotowanie i kuchnie świata oraz podróżowanie.`,
      jurij_text: `Kwalifikacje zawodowe lekarza dentysty uzyskałem na Narodowym Uniwersytecie Medycznym im. M. I. Pyrohova w Winnicy, w 2018 roku. Staż podyplomowy odbyłem w Centrum Stomatologii w Eskulapie (Ciechanów).

Umiejętności rozwijam przede wszystkim w kierunku stomatologii estetycznej, stomatologii mikroskopowej (w powiększeniu) oraz chirurgii stomatologicznej. Nieustannie podnoszę swoje kwalifikacje uczestnicząc w kursach podyplomowych teoretycznych i praktycznych.

W swojej pracy stawiam na indywidualne, pełne empatii i delikatności podejście do każdego pacjenta. Transparentność i świadomość pacjenta o przebiegu jego leczenia to jedna z podstaw wykonywanego przeze mnie zawodu. Staram się przeprowadzać leczenie bez stresu, budując przyjazną atmosferę z Pacjentem.`
    }
  },
  mounted() {
      const container = document.getElementById('team_section')
      gsap.fromTo(container.children[0], {x: '-=100', opacity: 0}, {x: 0, opacity: 1, stagger: 1.2, duration: 1, ease: 'easeInOut',
        scrollTrigger: {
          trigger: container,
          start: 'top 80%'
        }
      })
      const array1 = Array.from(container.children[1].children).slice(0, 4)
      const array2 = Array.from(container.children[1].children).slice(4, 8)
      Array.from(array1).forEach((card, index) => {
        gsap.fromTo(card, {x: `-=${(index+1)*100}`, opacity: 0}, {x: 0, opacity: 1, duration: (index+1)*0.5, delay: (index+1)*0.5, ease: 'easeInOut',
          scrollTrigger: {
            trigger: card,
            start: 'top 90%'
          }
      })})
      Array.from(array2).forEach((card, index) => {
        gsap.fromTo(card, {x: `+=${(index+1)*100}`, opacity: 0}, {x: 0, opacity: 1, duration: 1, delay: (index+1)*0.5, ease: 'easeInOut',
          scrollTrigger: {
            trigger: card,
            start: 'top 90%'
          }
      })})
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
    border: none;
    width: 100%;
    min-height: 100vh;
    background-color: $team;
    color: --color-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh;
    h1 {
        margin-left: 30px;
        width: 100%;
        max-width: 1000px;
        color: $h2;
        @media (max-width: 1023px) {
          margin-left: 0;
          text-align: center;
        }
    }
    .cards {
      display: flex;
      max-width: 1500px;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
    }
}
</style>
