<template>
    <transition
        @enter="enter"
        @leave="leave"
    >
        <nav v-if="loadNav && !mobile" ref="nav" class="nav">
            <div class="flex-box flex-box--a">
                <div class="flex-item" @click="moveToSection('about')">O nas</div>
                <div class="flex-item" @click="moveToSection('team')">Zespół</div>
                <div class="flex-item" @click="moveToSection('clinic')">Klinika</div>
            </div>
            <div class="flex-box flex-box--a">
                <div class="flex-item" @click="moveToSection('services')">Usługi</div>
                <div class="flex-item" @click="moveToSection('prices')">Cennik</div>
                <div class="flex-item" @click="moveToSection('contact')">Kontakt</div>
            </div>
        </nav>
    </transition>
    <transition
        @enter="enter"
        @leave="leave"
    >
        <nav v-if="loadNav && mobile" ref="nav" class="nav">
            <div class="flex-box flex-box--a">
                <menu-icon @clickFunc="clickFunc"/>
            </div>
        </nav>
    </transition>

    <nav id="f_nav" class="nav nav--fixed">
        <img @click="moveToSection('home')" class="logo" src="../../assets/stomdecia_logo_smaller.svg" alt="logo">
        <div v-if="!mobile" class="flex-box">
            <div class="flex-item" @click="moveToSection('about')">O nas</div>
            <div class="flex-item" @click="moveToSection('team')">Zespół</div>
            <div class="flex-item" @click="moveToSection('clinic')">Klinika</div>
        </div>
        <div v-if="!mobile" class="flex-box">
            <div class="flex-item" @click="moveToSection('services')">Usługi</div>
            <div class="flex-item" @click="moveToSection('prices')">Cennik</div>
            <div class="flex-item" @click="moveToSection('contact')">Kontakt</div>
        </div>
        <div v-if="mobile" class="flex-box icon">
            <menu-icon @clickFunc="clickFunc"/>
        </div>
    </nav>
    <transition
        @enter="enterMobileNav"
    >
    <nav v-if="mobileMenu" class="mobileNav">
        <div class="row">
            <img @click="moveToSection('home')" class="logo" src="../../assets/stomedica_logo_full.svg" alt="logo">
            <menu-icon :value="true" @clickFunc="hideMobileMenu"/>
        </div>
        <div class="flex-box">
            <div class="flex-item" @click="moveToSection('about')">O nas</div>
            <div class="flex-item" @click="moveToSection('team')">Zespół</div>
            <div class="flex-item" @click="moveToSection('clinic')">Klinika</div>
            <div class="flex-item" @click="moveToSection('services')">Usługi</div>
            <div class="flex-item" @click="moveToSection('prices')">Cennik</div>
            <div class="flex-item" @click="moveToSection('contact')">Kontakt</div>
        </div>
    </nav>
    </transition>
</template>
<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import MenuIcon from '../MenuIcon.vue'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default {
    props: {
        isActive: {
            type: Boolean,
            required: true
        },
        loadNav: {
            type: Boolean,
            required: true
        }
    },
    components: {
        MenuIcon
    },
    data: function () {
        return {
            windowWidth: window.innerWidth,
            fixedNavActive: false,
            showAnim: null,
            hideAnim: null,
            mobile: false,
            mobileMenu: false
        }
    },
    watch: {
        isActive: function() {
            if(this.isActive) {
                gsap.to('#f_nav', {
                    yPercent: -100,
                    duration: 0.2
                })
                this.fixedNavActive = false
            }
        },
        windowWidth: function(newWidth, oldWidth) {
            if (newWidth < 1025 && oldWidth >= 1025) this.mobile = true
            else if (newWidth >= 1025 && oldWidth < 1025) this.mobile = false
        }
    },
    mounted() {
        if(this.windowWidth < 1025) this.mobile = true
        else this.mobile = false
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        gsap.set('#f_nav', { yPercent: -100 })

        ScrollTrigger.create({
            start: "top bottom",
            end: 99999,
            onUpdate: (self) => {
                if(!this.isActive) {
                    if (self.direction === -1 && !this.fixedNavActive) {
                        gsap.to('#f_nav', {
                            yPercent: 0,
                            duration: 0.2
                        })
                        this.fixedNavActive = true
                    }
                    else if (self.direction !== -1 && this.fixedNavActive) {
                        gsap.to('#f_nav', {
                            yPercent: -100,
                            duration: 0.2
                        })
                        this.fixedNavActive = false
                    }
                }
            }
        })
    },
    methods: {
        clickFunc() {
            gsap.to('.normal', {
                opacity: 0,
                duration: 0.5,
                ease: 'easeInOut'
            })
            this.mobileMenu = true
        },
        hideMobileMenu() {
            gsap.to('.mobileNav', {
                x: '100%',
                duration: 1,
                ease: 'easeInOut',
                onComplete: () => {
                    if (this.mobile) gsap.to('.normal', {
                        opacity: 1,
                        duration: 0.5,
                        ease: 'easeInOut'
                    })
                    this.mobileMenu = false
                }
            })
        },
        onResize() {
            this.windowWidth = window.innerWidth
        },
        enter() {
            const flexs = document.getElementsByClassName('flex-box--a')
            gsap.set(flexs, { y: '-100%'})
            gsap.to(flexs, {
                y: 0,
                duration: 2,
                stagger: 0.2,
                ease: 'easeInOut'
            })
        },
        enterMobileNav() {
            gsap.set('.mobileNav', { x: '-100%'})
            gsap.to('.mobileNav', {
                x: 0,
                duration: 1,
                ease: 'easeInOut'
            })
        },
        leave() {
            const flexs = document.getElementsByClassName('flex-box--a')
            gsap.to(flexs, {
                y: '-100%',
                duration: 2,
                ease: 'easeInOut'
            })
        },
        moveToSection(index) {
            gsap.to(window, {duration: 1, scrollTo:{y:`#${index}_section`, offsetY:0}, ease: 'easeInOut'})
            // close the left bar
            if (this.mobileMenu) this.hideMobileMenu()
        },
    }
}
</script>
<style lang="scss" scoped>
.nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-column: 2 / 4;
    z-index: 999;
    letter-spacing: 3px;
    font-size: 21px;
    .logo {
        width: 191px;
        transform: scale(0.7);
        cursor: pointer;
    }
    .flex-box {
        display: flex;
        justify-content: space-around;
        align-self: start;
        padding: 40px 0;
        cursor: pointer;
        &:first-child {
            grid-column: 1;
            @media (max-width: 1024px) {
                grid-column: 2;
                justify-self: flex-end;
                margin-right: 50px;
            }
        }
        .flex-item {
            position: relative;
            overflow: hidden;
            padding-bottom: 5px;
            &:before {
                content: '';
                width: 100%;
                position: absolute;
                border-bottom: 2px solid #00838f;
                bottom: 0;
                left: 0;
                transition: transform 0.4s ease-in-out;
                transform: translateX(-100%);
            }
            &:hover {
                &:before {
                    transform: translateX(0);
                }
            }
        }
    }
    &--fixed {
        position: fixed;
        background-color: #33333380;
        grid-template-columns: 1fr 1fr 1fr;
        top: 0;
        left: 0;
        .icon {
            display: flex;
            justify-content: center;
            grid-column: 3;
        }
        .flex-box {
            &:first-child {
                grid-column: 2/3;
            }
        }
    }
}
.mobileNav {
    width: 100%;
    height: 100vh;
    background-color: #55555580;
    backdrop-filter: blur(12px);
    z-index: 999;
    position: fixed;
    padding: 0 5vw;
    box-sizing: border-box;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            transform: scale(0.7);
            width: 191px;
        }
    }

    .flex-box {
        margin-left: 50px;
        margin-top: 13vh;
        font-size: 24px;
        .flex-item {
            margin: 8px 0;
        }
    }
}
</style>