<template>
  <div ref="home" id="home_section" class="container">
      <div v-if="loadWrapper" ref="videoWrapper" class="video-wrapper" />
      <div ref="curtain" id="curtain">
          <div class="piece"/>
          <div ref="bordered" class="piece"/>
          <div class="piece"/>
      </div>
      <div :class="{ 'grid-content': loadWrapper, 'flex-content': !loadWrapper }">
        <header :class="{ center: !isVideoLoaded, 'top-left': isVideoLoaded }">
            <img @load="startRendering()" id="logo" class="logo" src="../../assets/stomedica_logo_full.svg" alt="logo">
            <Nav :isActive="isActive" :loadNav="loadNav" />
        </header>
        <div class="middle"/>
        <Content :loadContent="loadContent" />
      </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Nav from './Nav.vue'
import Content from './Content.vue'


gsap.registerPlugin(ScrollTrigger)

export default {
    name: 'Home',
    components: {
        Content,
        Nav
    },
    props: {
    },
    data: function () {
        return {
            isVideoLoaded: false,
            video: null,
            loadContent: false,
            loadNav: false,
            loadWrapper: false,
            isActive: true
        }
    },
    mounted() {
        ScrollTrigger.create({
            trigger: "#home_section",
            start: "top bottom",
            end: "bottom top",
            onToggle: self => {
                this.isActive = self.isActive
                if(this.isActive && this.video !== null)
                    this.video.style = 'display: block;'
                else if(!this.isActive && this.video !== null) this.video.style = 'display: none;'
            }
        })
    },
    methods: {
        startRendering() {
            this.video = document.createElement('video')
            this.video.src = require('../../assets/bg_video_s.mp4')
            this.video.loop = 'loop'
            this.video.muted = 'true'
            this.video.setAttribute('playsinline', 'playsinline');
            this.video.classList.add('bg-video')
            this.video.load()
            this.video.onloadeddata = () => {
                gsap.to('#logo', {
                    duration: 1.7,
                    opacity: 0,
                    scale: 1.7,
                    delay: 0.5,
                    ease: 'easeInOut',
                    onComplete: () => {
                        this.isVideoLoaded = true
                        this.loadNav = true
                        // this.showNav()
                        this.showCurtain()
                    }
                })
            }
        },
        showLogo() {
            gsap.set('#logo', {
                scale: 0.7,
            })
            gsap.to('#logo', {
                duration: 1,
                opacity: 1,
                delay: 0.2,
                ease: 'easeInOut',
            })
        },
        showNav() {
            const flexs = document.getElementsByClassName('flex-box')
            gsap.set(flexs, { y: '-100%'})
            gsap.to(flexs, {
                y: 0,
                duration: 2,
                stagger: 0.2,
                ease: 'easeInOut'
            })
        },
        showCurtain() {
            gsap.to('#curtain', {
                duration: 0.8,
                y: 0,
                ease: 'easeInOut',
                onComplete: () => {
                    this.showLogo()
                    this.loadNav = true
                    this.loadWrapper = true
                    gsap.to(this.$refs.bordered, {
                        duration: 0.8,
                        y: 0,
                        ease: 'easeInOut',
                        onComplete: () => {
                            // append video
                            this.$refs.videoWrapper.append(this.video)
                            this.video.play()
                            gsap.set('#curtain', {
                                background: 0
                            })
                            this.hideCurtain()
                        }
                    })
                }
            })
        },
        hideCurtain() {
            gsap.to(this.$refs.curtain.children, {
                duration: 0.8,
                yPercent: -100,
                stagger: 0.2,
                ease: 'easeInOut',
                onComplete: () => {
                    this.showLogo()
                    /*gsap.to(this.$refs.nav.children, {
                        duration: 0.8,
                        y: 0,
                        stagger: 0.2,
                        ease: 'easeInOut'
                    })*/
                    this.loadContent = true
                    gsap.to(this.$refs.bordered, {
                        duration: 0.8,
                        y: 0,
                        ease: 'easeInOut',
                        onComplete: () => {
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$border-color: #9e9e9e;
.container {
    width: 100%;
    height: 100vh;
    position: relative;
    color: #eee;
    .logo {
        width: 191px;
        @media (min-width: 1225px) {
            margin-left: 50px;
        }
    }
    .flex-content {
        display: flex;
        flex-direction: column;
        .nav {
            display: none;
        }
    }
    .grid-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 2fr;
        width: 100%;
        height: 100%;
        .middle {
            grid-column: 2;
            grid-row: 1 / 3;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
        }
    }
    .center {
        align-self: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 100vh;
    }
    .top-left {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column: 1 / 4;
        grid-row: 1;

        .nav {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            grid-column: 2 / 4;
            z-index: 9999;
            letter-spacing: 3px;
            font-size: 21px;
            .flex-box {
                display: flex;
                justify-content: space-around;
                align-self: start;
                padding: 40px 0;
                &:first-child {
                    grid-column: 1;
                }
            }
            &--fixed {
                position: fixed;
                background-color: #33333380;
                grid-template-columns: 1fr 1fr 1fr;
                .flex-box {
                    display: flex;
                    justify-content: space-around;
                    padding: 40px 0;
                    &:first-child {
                        grid-column: 2/3;
                    }
                }
            }

        }
    }
    #curtain {
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translateY(-100%);
        background-color: rgb(48, 48, 48);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .piece {
            background-color: rgb(48, 48, 48);
            &:nth-child(2) {
                transform: translateY(-100%);
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
            }
        }
    }
}
</style>
