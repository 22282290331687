<template>
  <div ref="services" id="services_section" class="container">
    <h1>Usługi</h1>
    <div class="services_container">
      <div class="service_item" @click="open(index)" v-for="index in 11" :key="index">
        <div class="icons--item" />
        <h2>{{ services_names[index-1] }}</h2>
      </div>
    </div>
    <pop-up id="popup" v-if="isOpenModal" @close="close()" @prev="prev()" @next="next()">
      <transition-group
        id="service_items"
        name="list" 
        tag="div"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <diagnostyka v-if="active===1" />
        <profilaktyka v-else-if="active===2" />
        <zachowawcza v-else-if="active===3" />
        <endodontcja v-else-if="active===4" />
        <chirurgia v-else-if="active===5" />
        <implantologia v-else-if="active===6" />
        <protetyka v-else-if="active===7" />
        <wybielanie v-else-if="active===8" />
        <periodontologia v-else-if="active===9" />
        <dziecieca v-else-if="active===10" />
        <ortodoncja v-else-if="active===11" />
      </transition-group>
    </pop-up>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { ImgComparisonSlider } from '@img-comparison-slider/vue'
import PopUp from '../PopUp.vue'
import Diagnostyka from './Diagnostyka.vue'
import Profilaktyka from './Profilaktyka.vue'
import Zachowawcza from './Zachowawcza.vue'
import Endodontcja from './Endodontcja.vue'
import Chirurgia from './Chirurgia.vue'
import Implantologia from './Implantologia.vue'
import Protetyka from './Protetyka.vue'
import Wybielanie from './Wybielanie.vue'
import Periodontologia from './Periodontologia.vue'
import Dziecieca from './Dziecieca.vue'
import Ortodoncja from './Ortodoncja.vue'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Services',
  components: {
    PopUp,
    Diagnostyka,
    Profilaktyka,
    Zachowawcza,
    Endodontcja,
    Chirurgia,
    Implantologia,
    Protetyka,
    Wybielanie,
    Periodontologia,
    Dziecieca,
    Ortodoncja
  },
  data: function () {
    return {
      isOpenModal: false,
      services_names: [
        'Diagnostyka RTG',
        'Profilaktyka',
        'Stomatologia Zachowawcza',
        'Leczenie endodontyczne',
        'Chirurgia Stomatologiczna',
        'Implantologia',
        'Protetyka',
        'Wybielanie zębów',
        'Periodontologia',
        'Stomatologia dziecięca',
        'Ortodoncja nakładkowa'
      ],
      active: 1,
      services: [],
      block: false,
      left: false
    }
  },
  mounted() {
    const element = document.getElementsByClassName('services_container')
    gsap.fromTo(element[0].children, {x: '-=100', opacity: 0}, {x: 0, opacity: 1, stagger: 0.3, duration: 0.7, ease: 'easeInOut',
      scrollTrigger: {
          trigger: element,
          start: 'top 80%'
      }
    })

    this.services = document.getElementsByClassName('service_content')
    this.openServiceDiv(this.active)
  },
  methods: {
    prev() {
      if (this.block) return
      this.block = true
      const el = document.getElementById('service_items')
      gsap.to(el.children[0], {x: '+100%', opacity: 0, duration: 1, onComplete: () => {
        this.active--
        if(this.active < 1)
          this.active = 11
        this.block = false
        this.left = true
      }})
    },
    next() {
      if (this.block) return
      this.block = true
      const el = document.getElementById('service_items')
      gsap.to(el.children[0], {x: '-100%', opacity: 0, duration: 1, onComplete: () => {
        this.active++
        if(this.active > 11)
          this.active = 1
        this.block = false
      }})
    },
    beforeEnter(el) {
      if(this.left) {
        gsap.set(el, {x: '-100%', opacity: 0})
        this.left = false
      }
      else
        gsap.set(el, {x: '+100%', opacity: 0})
    },
    enter(el) {
      gsap.to(el, {x: 0, opacity: 1, duration: 1})
    },
    /*leave(el) {
      gsap.to(el, {x: '-100%', opacity: 0, duration: 1})
    },*/
    open(index) {
      this.isOpenModal = true
      this.active = index
    },
    close() {
      this.isOpenModal = false
      this.active = 0
    },
    loadContent(icons) {
      const container = document.getElementsByClassName('service_item')
      let flag = false
      for (const property in icons) {
        if(property === '8') {
          flag = true
        }
        if(flag) {
          const index = parseInt(property) + 1
          if(!icons[index]) break
          container[property-1].children[0].appendChild(icons[index])
        } else
          container[property-1].children[0].appendChild(icons[property])
      }
    },
    closeServiceDiv(index, openNextAfter) {
      gsap.to(this.services[this.active-1], {y: '-100%', duration: 0.5, ease: 'easeInOut', onComplete: () => {
        this.active = index
        if(openNextAfter) this.openServiceDiv(index)
      }})
    },
    openServiceDiv(index) {
      gsap.to(this.services[index-1], {y: '0', duration: 0.5, ease: 'easeInOut', onComplete: () => {
        this.active = index
      }})
    },
    setActive(index) {
      // close active service div
      if (this.active === index) this.closeServiceDiv(0, false)
      // open if nothing is open
      else if(this.active === 0) this.openServiceDiv(index)
      else this.closeServiceDiv(index, true)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
    width: 100%;
    min-height: 100vh;
    background-color: $services;
    color: --color-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    h1 {
      color: $h2;
    }
    .services_container {
      display: flex;
      flex-wrap: wrap;
      max-width: 1500px;
      height: 100%;
      min-height: 80vh;
      gap: 85px;
      align-items: center;
      justify-content: center;
      padding: 20px;

      .service_item {
        h2 {
          font-size: 22px;
          @media (max-width: 1023px) {
              font-size: 18px;
          }
          @media (max-width: 600px) {
              font-size: 16px;
          }
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .content {
      position: sticky;
      top: 20px;
      max-width: 1500px;
      width: 100%;
      padding: 30px;
      h1 {
          font-size: 64px;
          color: $h2;
      }
    }
    .icons {
      display: flex;
      justify-content: center;
      background-color: white;

      &--item {
        position: relative;
        &:hover {
          cursor: pointer;
          &:before {
            content: attr(data-content);
            position: absolute;
            top: -20px;
            left: -100px;
            width: 300px;
            text-align: center;
          }
        }
        transition: all 0.5s ease-in-out;
        &--active {
          background-color: #43545e;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }
    .services {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: relative;
      .flex_box {
        display: flex;
        flex-wrap: wrap;
        .content {
          max-width: 600px;
        }

      }
      .service_content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #43545e;
        padding: 0 5vw;
        box-sizing: border-box;
        h2, h3 {
          color: $h1;
          margin-top: 5vh;
        }
        p {
          color: rgb(212, 212, 212);
          max-width: 600px;
        }
        transform: translateY(-100%);

        .slider_comparison {
          width: 100%;
          height: 100%;
          max-width: 400px;
        }
      }
    }

}
</style>
