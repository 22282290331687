<template>
    <div class="service_container">
        <h2>Leczenie endodontyczne</h2>
        <p>Endodoncja to dziedzina stomatologii, która zajmuje się chorobami miazgi zęba, 
          gdy bakterie dostaną się do tkanek położonych głębiej. Choroby miazgi objawiają się bólem zęba.</p>
        <p>Leczenie polega na usunięciu bakterii z korzeni zęba, odkażeniu oraz wypełnienie ich specjalną masą za pomocą precyzyjnych narzędzi i mikroskopu.
          Po zabiegu ząb wymaga wzmocnienia, dlatego lekarz stomatolog odbudowuje go włóknem szklanym, które tworzy dla zęba specjalne rusztowanie. 
          Następnie zabezpiecza ząb przy pomocy wypełnienia ceramicznego lub kompozytowego,
          Pozwala to zachować ząb i niweluje dolegliwości bólowe.</p>
        <p>Leczenie kanałowe wykonujemy tylko przy użyciu mikroskopu zabiegowego - jest to standard. Naszym pacjentom oferujemy tylko nowoczesne i najlepsze rozwiązania.</p>
        <video controls>
          <source :src="require('../../assets/videos/endodoncja.mp4')" @load="test" type="video/mp4">
        Twoja przeglądarka nie obsługuje video.
        </video>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>