<template>
    <div class="service_container">
        <h2>Stomatologia Zachowawcza</h2>
        <p>Stomatologia zachowawcza to dziedzina zajmująca się leczeniem ubytków próchnicowych, 
          które mogą prowadzić do poważnych powikłań ogólnoustrojowych oraz zachowaniem estetycznego wyglądu zębów.</p>
        <p>Leczenie próchnicy polega na oczyszczeniu i wypełnieniu ubytków wysokiej jakości materiałem, który zapobiega nawrotowi choroby. 
          W gabinetach Stomedica Waszczuk-Dziekan wszystkie wypełnienia (plomby) wykonujemy w powiększeniu, używając materiałów sprawdzonych i renomowanych producentów. 
          Wypełnienia wykonane w naszej klinice odwzorowują naturalną anatomię zęba (kształt, kolor, teksturę i połysk), 
          przywracają odpowiednią funkcję i są wysoce estetyczne.</p>
        <p>Jeśli ząb jest mocno zniszczony lekarz może także zaproponować nakłady kompozytowe i porcelanowe, 
          które są trwałe i idealnie dopasowane do ubytku. Dzięki temu znacznie lepiej przez długie lata chronią zęby przed próchnicą oraz złamaniem.</p>
        <p>Używamy kamery wewnątrzustnej, aby nasi Pacjenci widzieli na czym polega problem oraz w jaki sposób został rozwiązany.</p>
        <video controls>
          <source :src="require('../../assets/videos/zachowawcza.mp4')" @load="test" type="video/mp4">
        Twoja przeglądarka nie obsługuje video.
        </video>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>