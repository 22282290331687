<template>
    <div class="socialL">
        <a href="https://www.instagram.com/stomedica/" class="insta-icon" rel="noreferrer" target="_blank" aria-label="Instagram" />
        <a href="https://www.facebook.com/stomedicadentalclinic" class="face-icon" rel="noreferrer" target="_blank" aria-label="facebook" >
          <i class="icon fab fa-facebook-f"/>
        </a>
    </div>
</template>

<style lang="scss" scoped>
// Use this variable to resize the logo
$icon-size: 46px;
$font-size: calc($icon-size * 0.83);

// 36 25 11
a {
  text-decoration: none;
  color: white;
}
.socialL {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    //justify-content: space-evenly;
    color: white;
    width: 100%;
    max-width: 100px;

    .face-icon {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: #3d70d6;
        width: $icon-size;
        height: $icon-size;
        border-radius: 50%;
        font-size: $font-size;

    }
}

.insta-icon {
  position: relative;
  width: $icon-size;
  height: $icon-size;
  border-radius: 20%;
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
}
.insta-icon:after,
.insta-icon:before {
  position: absolute;
  top: 50%;
  left: 50%;

  width: calc($icon-size * 0.69);
  height: calc($icon-size * 0.69);
  border: 2px solid #fff;
  transform: translate(-50%, -50%);
  content: ''
}
.insta-icon:before {
  border-radius: 20%
}
.insta-icon:after {
  width: calc($icon-size * 0.3);
  height: calc($icon-size * 0.3);
  border-radius: 50%
}
</style>