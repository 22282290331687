<template>
    <div ref="about" id="about_section" class="container">
        <section class="content_top">
            <div class="grid_box">
                <article>
                    <h1>O nas</h1>
                    <p class="spacing">Klinika Stomedica Waszczuk – Dziekan to połączenie pasji i profesjonalizmu wykwalifikowanej kadry stomatologicznej. Wierzymy, że kluczem do pięknego, zdrowego uśmiechu jest kompleksowa opieka stomatologiczna oparta na współpracy między pacjentem, a lekarzem dentystą. Świadczone przez nas usługi charakteryzują się indywidualnym podejściem oraz są wykonane na najwyższej jakości sprzęcie. Naszą misją jest zapewnienie komfortu, oraz poprawienie jakości życia naszych pacjentów, ponieważ wiemy, że każdy z nas ma to w uśmiechu.</p>
                    <div class="social">
                        <p>Znajdź nas na mediach społecznościowych!</p>
                        <Social />
                    </div>
                </article>
                <div class="card">
                    <div class="card--content">
                        <a id="zl-url" class="zl-url" href="https://www.znanylekarz.pl/mateusz-dziekan/stomatolog-protetyk-chirurg-stomatologiczny/kolobrzeg" rel="nofollow" data-zlw-doctor="mateusz-dziekan" data-zlw-type="big" data-zlw-opinion="false" data-zlw-hide-branding="true">Mateusz Dziekan - ZnanyLekarz.pl</a>
                        <a id="zl-url" class="zl-url" href="https://www.znanylekarz.pl/laura-waszczuk-dziekan/stomatolog/kolobrzeg" rel="nofollow" data-zlw-doctor="laura-waszczuk-dziekan" data-zlw-type="big" data-zlw-opinion="false" data-zlw-hide-branding="true">Laura Waszczuk-Dziekan - ZnanyLekarz.pl</a>
                        <a id="zl-url" class="zl-url" href="https://www.znanylekarz.pl/yurii-obukhov/stomatolog/kolobrzeg" rel="nofollow" data-zlw-doctor="yurii-obukhov" data-zlw-type="big" data-zlw-opinion="false" data-zlw-hide-branding="true" data-zlw-saas-only="false">Yurii Obukhov - ZnanyLekarz.pl</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="content_bottom">
            <h2>Co nas wyróżnia</h2>
            <div class="items">
                <article class="item">
                    <h3>Jestesmy innowacyjni</h3>
                    <p>Nieustannie poszukujemy nowych technologii i rozwiązań w stomatologii, poprawiających jakość leczenia i efekty.</p>
                </article>
                <article class="item">
                    <h3>Podchodzimy kompleksowo do Twojego przypadku</h3>
                    <p>Bardzo precyzyjnie stawiamy diagnozę stosując do tego najnowszą aparaturę i technologię. Leczenie planujemy wspólnie z Tobą, pamiętając o tym że naszym zadaniem jest nie tylko wyleczenie Twoich zębów, ale też zdrowie i piękny uśmiech.</p>
                </article>
                <article class="item">
                    <h3>Widzimy więcej i lepiej</h3>
                    <p>Wszystkie zabiegi wykonujemy w bardzo dużym powiększeniu stosując
                        najnowsze lupy i Mikroskop dzięki czemu precyzyjnie leczymy Twoje zęby.</p>
                </article>
                <article class="item">
                    <h3>Przymierz swój nowy uśmiech</h3>
                    <p>Dzięki innowacyjnej technologii Digital Smile Design możemy
                        zobrazować efekty leczenia. Pozwala Ci to zobaczyć, a nawet przymierzyć swój przyszły uśmiech
                        jeszcze przed rozpoczęciem leczenia.</p>
                </article>
                <article class="item">
                    <h3>Dajemy Ci wybór</h3>
                    <p>Nie narzucamy jednej ścieżki leczenia, zawsze przedstawiamy alternatywne opcje
                        leczenia, w konsekwencji tego możesz wybrać dogodną dla siebie i najbardziej przystępną cenowo opcję,
                        jednocześnie przy zachowaniu najwyższej jakości materiałów i metod leczenia.</p>
                </article>
                <article class="item">
                    <h3>Leczymy od podstaw</h3>
                    <p>Krwawiące dziąsła? Nieświeży oddech? Każde kompleksowe leczenie
                        zaczynamy od profesjonalnego oczyszczenia u naszych higienistek. Dzieki urządzeniu EMS, które w
                        delikatny sposób pozbywa się kamienia i przebarwień dbamy o to, aby Twoje zęby były czyste, gładkie,
                        a dziąsła zdrowe bez uciążliwego krwawienia. Dodatkowo urządzenie EMS podgrzewa używaną
                        podczas oczyszczania wodę, zmniejszając znacznie nieprzyjemną nadwrażliwość.</p>
                </article>
                <article class="item">
                    <h3>Nieustannie podnosimy swoje umiejętności</h3>
                    <p>Aby zapewnić Wam najlepszą jakość usług stale
                        podnosimy swoje kwalifikacje i umiejętności uczestnicząc w szkoleniach, kursach i konsyliach
                        prowadzonych przez światowej klasy specjalistów.</p>
                </article>
                <article class="item">
                    <h3>Dbamy o atmosferę i komfort</h3>
                    <p>Od przekroczenia naszego progu dbamy o to, abyś czuł sie
                        komfortowo. W recepcji czeka na Ciebie aromatyczna kawa, grająca w tle muzyka i uśmiechnięty
                        personel. Zabiegi wykonujemy w klimatyzowanych gabinetach, oraz na najwygodniejszych unitach
                        stomatologicznych Sinius wyposażonych w wysokiej jakości miękką tapicerkę, funkcję podparcia
                        lędźwiowego oraz podłokietnikami dbającymi o Twoją wygodę.</p>
                </article>
                <article class="item">
                    <h3>Widzimy to co niewidoczne</h3>
                    <p>Posiadamy potężne urządzenie jakim jest bez wątpienia tomografia
                        Orthophos SL 3D. Dzięki dużemu obszarowi działania i minimalnej dawce promieniowania umożliwia
                        nam trójwymiarową i precyzyjną diagnostykę chorób i struktur, które są niewidoczne gołym okiem.</p>
                </article>
                <article class="item">
                    <h3>Zapomnij o wyciskach</h3>
                    <p>Możesz zapomnieć o dyskomforcie i stresie związanym z pobieraniem
                        klasycznych wycisków. Zamiast tradycyjnej metody używamy najnowszy skaner wewnątrzustny
                        Primescan rejestrujący ponad milion punktów 3D na sekundę umożliwiający stworzenie wirtualnego
                        modelu Twoich zębów.</p>
                </article>
            </div>
        </section>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Social from '../Social.vue'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'About',
  components: {
      Social
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  mounted() {
    const container = document.getElementById('about_section')
    const top = document.getElementsByClassName('grid_box')
    const items = document.getElementsByClassName('items')
    gsap.fromTo(top[0].children[0].children, {x: '-=100', opacity: 0}, {x: 0, opacity: 1, stagger: 0.5, duration: 1, ease: 'easeInOut',
    scrollTrigger: {
        trigger: container,
        start: 'top 80%'
    }
    })
    gsap.fromTo(top[0].children[1], {x: '+=400', opacity: 0}, {x: 0, opacity: 1, stagger: 0.5, duration: 2, ease: 'easeInOut',
    scrollTrigger: {
        trigger: container,
        start: 'top 30%'
    }
    })

    gsap.fromTo(items[0].children, {x: '-=100', opacity: 0}, {x: 0, opacity: 1, stagger: 0.5, duration: 1, ease: 'easeInOut',
    scrollTrigger: {
        trigger: items[0],
        start: 'top bottom'
    }
    })

    const widzet = document.createElement('script')
    widzet.innerHTML = !function($_x,_s,id){var js,fjs=$_x.getElementsByTagName(_s)[0];if(!$_x.getElementById(id)){js = $_x.createElement(_s);js.id = id;js.src = "//platform.docplanner.com/js/widget.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","zl-widget-s");
    widzet.innerHTML = !function($_x,_s,id){var js,fjs=$_x.getElementsByTagName(_s)[0];if(!$_x.getElementById(id)){js = $_x.createElement(_s);js.id = id;js.src = "//platform.docplanner.com/js/widget.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","zl-widget-s");
    container.appendChild(widzet)
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spacing {
    line-height: 1.6;
}
.social {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    // padding: 20px;
    // margin-left: 150px;
    align-items: center;
    p {
        margin-top: 150px;
        font-weight: bold;
    }
}
.icon {
    font-size: 21px;
    color: white;
}
.instagram {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;
    /* Also define standard property for compatibility */
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 200px; /* change this to change the size*/
    color: white;
}
.container {
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content_top {
        h1 {
            text-align: left;
            color: #fff;
        }
        p {
            color: #eee;
        }
        background-color: #8cb4be;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 50vh;
        position: relative;
        padding-top: 40px;
        .grid_box {
            display: grid;
            grid-template-columns: minmax(200px, 800px) minmax(260px, 400px);
            max-width: 1500px;
            gap: 30px;
            overflow: visible;
            padding: 0 20px;
            @media (max-width: 1024px) {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto;
            }
            p {
                max-width: 700px;
            }
            .card {
                background-color: #fff;
                border-radius: 6px;
                min-width: 240px;
                max-width: 400px;
                min-height: 500px;
                padding: 10px 20px;
                margin-top: 30px;
                -webkit-box-shadow: 5px 5px 19px 0px rgba(185, 185, 185, 1);
                -moz-box-shadow: 5px 5px 19px 0px rgba(185, 185, 185, 1);
                box-shadow: 5px 5px 19px 0px rgba(185, 185, 185, 1);
                z-index: 1;
                @media (max-width: 1024px) {
                    justify-self: center;
                    width: 100%;
                }
                &--content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }
            }
        }
        &:after {
            content: '';
            position: absolute;
            height: 100px;
            background-color: #fff;
            bottom: -70px;
            right: 0;
            left: 0;
            transform: skewY(-3deg);
        }
    }
    .content_bottom {
        min-height: 50vh;
        width: 50%;
        @media (max-width: 1023px) {
            width: 90%;
        }
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        p {
            line-height: 1.6;
        }
        .items {
            .item {
                margin-top: 40px;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    width: 20%;
                    border-bottom: solid 1px rgb(148, 141, 141);
                    bottom: -20px;
                    left: 0;
                }
            }
        }
    }
}
</style>
