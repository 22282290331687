<template>
    <div class="service_container">
        <h2>Profilaktyka</h2>
        <p>Profilaktyka jest najważniejszą dziedziną stomatologii, gdyż wielu problemom stomatologicznym można zapobiegać - 
          dzięki temu możemy uniknąć długiego i kosztownego leczenia.</p>
        <p>Profilaktyczna higienizacja zębów to 4-etapowy zabieg oczyszczania powierzchni zębów z kamienia i osadu, a następnie zabezpieczenie i wzmocnienie szkliwa. 
          Zaczynamy od skalingu, aby pozbyć się kamienia.</p>
        <p>Kamień nazębny to zmineralizowana płytka nazębna. Jest bardzo niebezpieczny, gdyż może powodować chorobę przyzębia (paradontozę) oraz chorobę dziąseł, 
          przez to, że kumulują się tam bakterie.</p>
        <p>Po skalingu kolejnym etapem jest piaskowanie, czyli usuwanie osadu i przebarwień nawet z trudno dostępnych miejsc. 
          Kolejnym etapem jest fluoryzacja, która zabezpiecza zęby przed próchnicą i działaniem kwasów z pożywienia, wspomaga odbudowę szkliwa i 
          utrudnia odkładanie kamienia po zabiegu. Ostatnim etapem jest dokładny instruktaż higieny jamy ustnej indywidualnie dobrany do potrzeb pacjenta.</p>
        <p>Higienizacja jest bezbolesna i komfortowa dla pacjenta. Wykonują ją doświadczone higienistki stomatologiczne.</p>
        <p>Powtarzana regularnie co 3-6 miesięcy (w zależności od potrzeb pacjenta) zmniejsza stan zapalny w jamie ustnej, nadwrażliwość, 
          krwawienie z dziąseł oraz nieprzyjemny oddech, a także znacząco zmniejsza ryzyko próchnicy zębów. 
          Zapobiega także chorobie przyzębia, która prowadzi do utraty zębów.</p>
        <video controls>
          <source :src="require('../../assets/videos/profilaktyka.mp4')" @load="test" type="video/mp4">
        Twoja przeglądarka nie obsługuje video.
        </video>
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>