<template>
  <section ref="prices" id="prices_section" class="container">
    <h1>Cennik</h1>
    <div class="content">
      <div class="col">
        <article class="row">
          <div class="service_icon" />
          <h2>Diagnostyka RTG</h2>
          <article class="col2">
            <p>Tomografia 3D (pacjent Klinika) 200 zł</p>
            <p>Tomografia 3D (pacjent zewnętrzny) 400 zł</p>
            <p>Tomografia 3D częściowa (pacjent zewnętrzny) 250 zł</p>
            <p>Zdjęcie panoramiczne/pantomogram 150 zł</p>
            <p>Zdjęcie cefalometryczne 150 zł</p>
            <p>Diagnostyka wraz z planem leczenia 150 zł</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Konsultacje</h2>
          <article class="col2">
            <p>Kompleksowa diagnostyka wraz z planem leczenia 150 zł</p>
            <p>Badanie jamy ustnej 150 zł</p>
            <p>Badanie pacjenta pierwszorazowego 150 zł</p>
            <p>Badanie z pisemnym zaświadczeniem 200 zł</p>
            <p>Wizyta kontrolna 100 zł</p>
            <p>Konsultacja chirurgiczna z tomografią 3D 250 zł</p>
            <p>Konsultacja implantologiczna z tomografią 3D 300 zł</p>
            <p>Konsultacja protetyczna z planem leczenia 200 zł</p>
            <p>Konsultacja ortodontyczna 200 zł</p>
            <p>Konsultacja ortodontyczna wraz z diagnostyką 450 zł</p>
            <p>Konsultacja endodontyczna z tomografią 3D 250 zł</p>
            <p>Konsultacja implantologiczna 250 zł</p>
            <p>Konsultacja przed zabiegiem z zakresu medycyny estetycznej bezpłatna</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Profilaktyka</h2>
          <article class="col2">
            <p>Pakiet oczyszczania (skaling+piaskowanie+fluoryzacja) 300 zł</p>
            <p>Pakiet oczyszczania w znieczuleniu 350 zł</p>
            <p>Pakiet oczyszczania Junior (dla dzieci do 12 r.ż) 250 zł</p>
            <p>Lakierowanie zębów 100 zł</p>
            <p>Lakowanie zęba/lakowanie poszerzone 150 zł - 200 zł/ ząb</p>
            <p>Piaskowanie lub skaling 200 zł</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Stomatologia Zachowawcza</h2>
          <article class="col2">
            <p>Wypełnienie w zębie stałym (w zależności od wielkości ubytku) 200-450 zł</p>
            <p>ICON 250 zł/jeden ząb; 200 zł/ząb (co najmniej 2 zęby na jednej wizycie)</p>
            <p>Inlay/Onlay kompozytowy bezpośredni 1100 zł</p>
            <p>Licówka kompozytowa bezpośrednia 500-600 zł</p>
            <p>Opatrunek leczniczy/wizyta bólowa 200 zł</p>
            <p>Opatrunek tymczasowy 100 zł</p>
            <p>Recepta 100 zł</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Protetyka</h2>
          <article class="col2">
            <p>Cyfrowe projektowanie uśmiechu DSD - wizualizacja 500 zł</p>
            <p>Cyfrowe projektowanie uśmiechu DSD wraz z przymiarką prototypu 1200 zł (jeden łuk)</p>
            <p>Deprogramacja w gabinecie 400 zł</p>
            <p>Deprogramacja wg Kois'a 700 zł</p>
            <p>Mock up/Wax up (symulacja 1 ząb) 120 zl</p>
            <p>Korona pełnoceramiczna/cyrkonowa 2000-2400 zł</p>
            <p>Korona tymczasowa wykonana w laboratorium 500 zł</p>
            <p>Korona tymczasowa wykonana w gabinecie 400 zł</p>
            <p>Licówka pełnoceramiczna 2000-2400 zł</p>
            <p>Licówka kompozytowa wykonana w laboratorium 900 zł</p>
            <p>Licówka kompozytowa/bonding/flow injection 700 zł/ząb</p>
            <p>Most pełnoceramiczny (cena za punkt) 1900 zł</p>
            <p>Nakład kompozytowy inlay/onlay/overlay 1200 zł</p>
            <p>Nakład ceramika hybrydowa 1500 zł</p>
            <p>Nakład pełnoceramiczny inlay/onlay/overlay 2000 zł</p>
            <p>Proteza Premium akrylowa/szkieletowa/nylonowa 3000 zł</p>
            <p>Proteza zębowa natychmiastowa tymczasowa 1500 zł</p>
            <p>Szyna relaksacyjna 800 zł</p>
            <p>Wkład koronowo-korzeniowy tytanowy 300 zł</p>
            <p>Odbudowa na włóknie szklanym 450 zł</p>
            <p>Zatrzask/zasuwa 700 zł</p>
            <p>Łyżka indywiduana 150 zł</p>
            <p>Dostawienie zęba do protezy 300-400 zł</p>
            <p>Podścielenie protezy 400 zł</p>
            <p>Naprawa protezy z wyciskiem 400 zł</p>
            <p>Osadzenie korony spoza gabinetu (zacementowanie) 300 zł</p>
            <p>Zdjęcie korony/licówki 100 zł</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Ortodoncja nakładkowa</h2>
          <article class="col2">
            <p>Diagnostyka ortodontyczna wraz z planem leczenia (zdjęcie cefalometryczne, pantomogram, skany, zdjęcia wewnątrzustne i zewnątrzustne) 450 zł</p>
            <p>Leczenie nakładkowe (całość) Pakiet Light od 5000 zł</p>
            <p>Leczenie nakładkowe (całość) Pakiet Full 16000 zł</p>
            <p>Retainer 400-1000 zł</p>
          </article>
        </article>
      </div>
      <div class="col">
        <article class="row">
          <div class="service_icon" />
          <h2>Wybielanie zębów</h2>
          <article class="col2">
            <p>Wybielanie nakładkowe domowe 800 zł</p>
            <p>Dodatkowa strzykawka żelu do wybielania domowego 50 zł</p>
            <p>Wybielanie hybrydowe(gabinetowe + nakładki) 1500 zł</p>
            <p>Wybielanie metodą regeneracyjną PREVDENT 1600 zł</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Medycyna estetyczna</h2>
          <article class="col2">
            <p>Modelowanie ust 1000 zł</p>
            <p>Toksyna botulinowa Azzalure/Azzulience<br>(lwia zmarszczka, kurze łapki, poprzeczne czoła)
              <span style="text-align: center;"><br>1 okolica 500 zł</span>
              <span style="text-align: center;"><br>2 okolice 900 zł</span>
              <span style="text-align: center;"><br>3 okolice 1100 zł</span>
            </p>
            <p>Zmarszczki królicze 300 zł</p>
            <p>Gummy smile 300 zł</p>
            <p>Lip flip/zmarszczki palacza 300 zł</p>
            <p>Broda brudkowana/czarownicy 300 zł</p>
            <p>Bruksizm 1000 zł</p>
            <p>Unoszenie brwi 300 zł</p>
            <p>Toksyna botulinowa Azzalure Abo Liquid<br>(lwia zmarszczka, kurze łapki, poprzeczne czoła)
              <span style="text-align: center;"><br>1 okolica 800 zł</span>
              <span style="text-align: center;"><br>2 okolice 1300 zł</span>
              <span style="text-align: center;"><br>3 okolice 1600 zł</span>
            </p>
            <p>Mezoterapia neuvia hydrodeluxe<br>twarz 800 zł<br>twarz+szyja 1100 zł</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Chirurgia Stomatologiczna</h2>
          <article class="col2">
            <p>Usunięcie zęba stałego 250-450zł</p>
            <p>Usunięcie zęba ósemki 400-1000 zł</p>
            <p>Usunięcie zawiązka zęba (germektomia) 500-1000 zł</p>
            <p>Resekcja wierzchołka korzenia 1200zł</p>
            <p>Podcięcie wędzidełka 300 zł</p>
            <p>Wycięcie kaptura dziąsłowego 300 zł</p>
            <p>Plastyka dziąsła 300 zł/ ząb</p>
            <p>Opatrunek chirurgiczny 50 zł</p>
            <p>Usunięcie szwów spoza gabinetu 100 zł</p>
            <p>Usunięcie szwów pacjenci Kliniki 0 zł</p>
            <p>Nacięcie ropnia 50 zł/100 zł</p>
            <p>Opatrunek chirurgiczny Nipas 50zł-150 zł (1-3 wizyty)</p>
            <p>Wycięcie zmiany lub torbieli z badaniem hist-pat 400-700zł</p>
            <p>Zamknięcie połączenia ustno-zatokowego spoza gabinetu 400 zł</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Periodontologia</h2>
          <article class="col2">
            <p>Kiretaż otwarty 400 zł</p>
            <p>Kiretaż zamknięty 1/4 uzębienia 300 zł</p>
            <p>Pokrycie recesji dziąsła/przeszczep dziąsła 1500 zł</p>
            <p>Pokrycie recesji dziąsłowej membraną 2000 zł</p>
            <p>Płukanie kieszonki dziąsłowej 100 zł</p>
            <p>Szynowanie rozchwianych zębów 150 zł za ząb</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Implantologia</h2>
          <article class="col2">
            <p>Wszczepienie implantu ASTRA TECH z dożywotnią gwarancją 3000 zł</p>
            <p>Korona na implancie 3000 zł</p>
            <p>Augmentacja (odbudowa kości) od 1000 zł</p>
            <p>Proteza ruchoma na implantach od 14000 zł</p>
            <p>All on X - wycena indywidualna</p>
            <p>Korona tymczasowa na implancie (korona + łącznik) 500-700 zł</p>
          </article>
        </article>
        <article class="row">
          <div class="service_icon" />
          <h2>Leczenie endodontyczne (kanałowe) pod mikroskopem</h2>
          <article class="col2">
            <p>Odbudowa zęba do leczenia endodontycznego 250-350 zł</p>
            <p>Leczenie endodontyczne siekacze/kły 1000 zł</p>
            <p>Leczenie endodontyczne przedtrzonowce 1200 zł</p>
            <p>Leczenie endodontyczne trzonowce 1500 zł</p>
            <p>Powtórne leczenie endodontyczne (cena za kanał) 150 zł + koszt leczenia endodontycznego</p>
            <p>Odbudowa zęba przy użyciu włókna szklanego 500 zł</p>
            <p>Usunięcie złamanego narzędzia 350-500 zł</p>
            <p>Użycie MTA 250 zł</p>
            <p>Zamknięcie perforacji 300zł</p>
            <p>Inlay/Onlay kompozytowy 1200 zł</p>
            <p>Usunięcie wkładu koronowo-korzeniowego 300-400 zł</p>
          </article>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Prices',

  data: function () {
    return {
      icon_order: [1, 8, 2, 3, 7, 12, 9, 9, 5, 10, 6, 4]
    }
  },
  mounted() {
      const container = document.getElementById('prices_section')
      gsap.fromTo(container.children, {x: '-=100', opacity: 0}, {x: 0, opacity: 1, stagger: 0.4, duration: 1})
  },
  methods: {
    loadContent(icons) {
      const container = document.getElementsByClassName('service_icon')
      for (const property in icons) {
        container[property-1].appendChild(icons[this.icon_order[property-1]].cloneNode(true))
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
    width: 100%;
    min-height: 100vh;
    background-color: $prices;
    color: --color-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: 3px 0;
    }
    h1 {
      color: $h2;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 1024px) {
          flex-direction: column;
          align-items: center;
          max-width: 612px;
      }
      max-width: 1500px;
      .row {
        display: grid;
        grid-template-columns: 100px auto;
        margin: 21px 0;
        @media (max-width: 1024px) {
          grid-template-columns: 100px auto;
        }
      }
      .col {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @media (min-width: 1024px) {
          max-width: 50%;
        }

      }
      .col2 {
        display: flex;
        flex-direction: column;
        grid-column: 2/3;
        @media (max-width: 1024px) {
          grid-column: 1/3;
          padding: 0 21px;
        }
        p {
          padding: 5px;
          &:nth-child(odd) {
            background-color: grey;
            color: rgb(245, 245, 245);
          }
          color: rgb(49, 48, 48);
        }
      }
    }
}
</style>
