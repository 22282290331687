<template>
    <div class="service_container">
        <h2>Wybielanie zębów</h2>
        <p>Wybielanie zębów w naszej Klinice jest przeprowadzane na dwa sposoby:</p>
        <ul>
          <li>wybielanie nakładkowe inaczej wybielanie domowe jest kontrolowane przez lekarza stomatologa. 
            Zawsze jest poprzedzone przeglądem jamy ustnej, aby właściwie zakwalifikować pacjenta do zabiegu oraz higienizacją zębów, 
            która znacznie zwiększa skuteczność zabiegu. Po wykonaniu przeglądu i oczyszczania pacjent ma wykonane cyfrowe wyciski pod szyny wybielające, 
            które następnie otrzymuje wraz żelami wybielającymi i indywidualną instrukcją stosowania.</li>
          <li>wybielanie gabinetowe metodą Prevdent to zabieg jednorazowy, wykonywany w gabinecie, 
            trwający około 2,5 godzin. Zabieg poprzedzony jest dokładnym badaniem stomatologicznym i wymaga zakwalifikowania przez lekarza stomatologa.
            Metoda Prevdent to metoda wybielająco - regeneracyjna, całkowicie niwelująca nadwrażliwość pozabiegową. 
            Polega na nałożeniu preparatu wybielającego wraz z hydroksyapatytem, który dodatkowo wzmacnia szkliwo. Preparat naświetlamy specjalną lampą. </li>
        </ul>
        <p>Warto pamiętać, że w przypadku wybielania zębów, zarówno nakładkowego jak i metodą PREVDENT gabinetową wypełnienia kompozytowe, 
          odbudowy porcelanowe oraz zęby leczone kanałowo nie wybielają się, dlatego należy liczyć się z tym, że wypełnienia mogą wymagać wymiany na jaśniejszy kolor.</p>
            
        <ImgComparisonSlider class="slider_comparison">
          <!-- eslint-disable -->
          <img
            slot="first"
            style="width: 100%; max-width: 500px;"
            :src="require('../../assets/przed-min.png')"
          />
          <img
            slot="second"
            style="width: 100%; max-width: 500px;"
            :src="require('../../assets/po-min.png')"
          />
          <!-- eslint-enable -->
        </ImgComparisonSlider> 
    </div>
</template>

<script>
import { ImgComparisonSlider } from '@img-comparison-slider/vue'

export default {
  components: {
    ImgComparisonSlider
  },
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>
.slider_comparison {
  max-width: 500px;
}

ul {
  line-height: 1.6;
  color: #343;
}
</style>