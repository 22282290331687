<template>
  <Home ref="home" />
  <About ref="about" />
  <Team ref="team" />
  <Clinic ref="clinic" />
  <Services ref="services" />
  <Prices ref="prices" />
  <Contact ref="contact"/>
</template>

<script>
import Home from './components/home/NewHome.vue'
import About from './components/about/About.vue'
import Team from './components/team/Team.vue'
import Clinic from './components/clinic/Clinic.vue'
// import NewClinic from './components/clinic/NewClinic.vue'
import Services from './components/services/Services.vue'
import Prices from './components/prices/Prices.vue'
import Contact from './components/contact/Contact.vue'

export default {
  name: 'App',
  components: {
    Home,
    About,
    Team,
    Clinic,
    // NewClinic,
    Services,
    Prices,
    Contact
  },
  data: function () {
    return {
      icons: {},
      icons_size: 12,
      icons_loaded: 0
    }
  },
  mounted () {
    this.loadIcons()
  },
  methods: {
    loadIcons() {
      for(let i=1; i<=this.icons_size; i++) {
        const img = new Image()
        img.src = require(`./assets/icons/i${i}.png`)
        img.alt = 'service icon'
        // img.classList.add('service_icon')
        this.icons[i] = img
        img.onload = () => {
          this.icons_loaded++
          if (this.icons_loaded === this.icons_size) {
            this.$refs.prices.loadContent(this.icons)
            this.$refs.services.loadContent(this.icons)
          }
        }
      }
    }
  }
}
</script>

<style>
.service_container {
  display: flex;
  flex-direction: column;
  min-height: 88vh;
  height: 100%;
  padding: 0 20px;
}

.service_container p {
  color: #333;
  line-height: 1.6;
  margin-left: 5px;
}

.service_container h2 {
  color: #598d86;
}

.navigation {
    display: flex;
    width: 100%;
    max-width: 200px;
    justify-content: space-between;
}
.bottom {
  padding-top: 25px;
  margin-top: auto;
}
.service_icon {
  width: 100px;
}
video {
  width:100%;
}
@font-face {
    font-family: Metropolis-black;
    src: url(Metropolis-Black.otf);
  }
@font-face {
    font-family: Metropolis-light;
    src: url(Metropolis-Light.otf);
  }
h1 {
  color: #00838f;
}
body, html {
  margin: 0;
  width: 100%;
  max-width: 100%;
  padding: 0;
  font-family: Metropolis-light, Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7a8a9b;
  display: flex;
  flex-direction: column;
}

img {
  width: 100%;
}

.main_content {
  width: 100%;
  height: 100vh;
  margin-top: 50vh;
}
.img_section {
  display: block;
  width: 100%;
  height: 70vh;
  object-fit: none;
  z-index: -2;
}

.fixed {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top:0;
    left: 0;
    object-fit: cover;
    object-position: center;
}

.video-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -11;
  touch-action: none;
  user-select: none;
}

.video-wrapper:after {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  opacity: 0.5;
  background-color: #333;
}

.bg-video {
  height: 100vh;
  width: 177.77777778vh;
  min-width: 100%;
  min-height: 56.25vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

}


</style>
