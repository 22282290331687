<template>
    <div class="service_container">
        <h2>Ortodoncja nakładkowa</h2>
        <p>Leczenie ortodontyczne poprzedzone jest dokładną diagnostyką -  wykonaniem modeli diagnostycznych, zdjęć fotograficznych oraz zdjęć rentgenowskich.</p>
        <p>W Stomedica Waszczuk-Dziekan wykonujemy leczenie nakładkowe, czyli tzw niewidzialną ortodoncję.</p>
        <p>Nakładki ortodontyczne są zaprojektowane przy użyciu zaawansowanego oprogramowania, 
          aby mieć pewność, że są doskonale dopasowane i zapewniają dokładne przesunięcia zębów potrzebne do osiągnięcia idealnego uśmiechu.</p>
        <p>Nakładki są estetyczną alternatywą dla klasycznych aparatów ortodontycznych.</p>
    </div> 
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>