<template>
    <div>
      <h1>Polityka prywatności</h1>
      <p>Stomedica Waszczuk-Dziekan, z siedzibą w Kołobrzegu, ul. Żółkiewskiego 1A, 78-100 Kołobrzeg, wypełniając obowiązki wynikające z obowiązujących przepisów prawa dotyczących ochrony danych osobowych, w tym Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej jako RODO), przekazuje Państwu informacje dotyczące przetwarzania i ochrony Państwa danych osobowych.</p>
      <p>Informacje zawarte w Polityce prywatności informują, jakie dane osobowe zbiera i przetwarza Stomedica Waszczuk-Dziekan, w jakim celu są one wykorzystywane oraz jakie przysługują Państwu prawa w związku z ochroną danych osobowych.</p>
<ol>
   <li><strong>Kto zbiera i przetwarza państwa dane osobowe (kto jest administratorem państwa danych osobowych)?</strong></li>
</ol>
<p>Stomedica Waszczuk-Dziekan z siedzibą w Kołobrzegu, ul. Żółkiewskiego 1A, 78-100 Kołobrzeg.</p>
<ol start="2">
   <li><strong>Kontakt z Stomedica Waszczuk-Dziekan?</strong></li>
</ol>
<p>Mogą Państwo skontaktować się z Stomedica Waszczuk-Dziekan:</p>
<ul>
   <li>za pomocą e-maila: <a href="mailto:recepcja@stomedica.pl">recepcja@stomedica.pl</a>,</li>
   <li>za pomocą telefonu: (+48) 510 234 111,</li>
   <li>drogą pocztową: ul. Żółkiewskiego 1A, 78-100 Kołobrzeg,</li>
</ul>
<p>&nbsp;</p>
<ol start="3">
   <li><strong>Jakie dane osobowe zbiera i przetwarza Stomedica Waszczuk-Dziekan?</strong></li>
</ol>
<p>Stomedica Waszczuk-Dziekan zbiera i przetwarza dane osobowe w zakresie niezbędnym do zrealizowania celu, w którym zostały zebrane. W zależności od celu oraz podstawy prawnej zebrania i przetwarzania danych osobowych Stomedica Waszczuk-Dziekan może zbierać i przetwarzać m.in. następujące dane:</p>
<ul>
   <li>dane identyfikujące, w tym między innymi: imię, nazwisko, PESEL, data urodzenia;</li>
   <li>dane kontaktowe, w tym między innymi: adres, numer telefonu, adres e-mail;</li>
   <li>dane zbierane i przetwarzane do postawienia diagnozy i przeprowadzenia procesu leczenia, w tym w szczególności dane dotyczące stanu zdrowia, z zastrzeżeniem, że dotyczy to wyłącznie osób korzystających ze świadczonych przez Stomedica Waszczuk-Dziekan usług medycznych.</li>
</ul>
<ol start="4">
   <li><strong>W jakim celu oraz na jakiej podstawie prawnej Stomedica Waszczuk-Dziekan przetwarza dane osobowe oraz jak długo są przechowywane</strong>?</li>
</ol>
<ul>
   <li>Osoby korzystające z usług medycznych świadczonych przez Stomedica Waszczuk-Dziekan (Pacjenci)</li>
</ul>
<table>
   <tbody>
      <tr>
         <td><strong>Cel przetwarzania danych</strong></td>
         <td><strong>Podstawa prawna przetwarzania danych</strong></td>
         <td><strong>Okres przechowywania/przetwarzana danych osobowych</strong></td>
      </tr>
      <tr>
         <td>Udzielanie świadczeń zdrowotnych, w tym prowadzenie dokumentacji medycznej; realizacja umowy na świadczenie usług medycznych</td>
         <td>art. 6 ust. 1 lit b lub c RODO oraz art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta oraz Rozporządzenia Ministra Zdrowia w sprawie rodzajów, zakresu i wzorów dokumentacji medycznej oraz sposobu jej przetwarzania (dalej jako Rozporządzenie Ministra Zdrowia)</td>
         <td>Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Zapewnienie opieki zdrowotnej oraz zarządzanie systemami i usługami opieki zdrowotnej, np. identyfikacji tożsamości podczas rejestracji oraz przed udzielaniem świadczeń zdrowotnych</td>
         <td>art. 6 ust. 1 lit. c oraz art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 i 26 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta, §10 ust. 1 pkt 2 Rozporządzenia Ministra Zdrowia, art. 32 w zw. z art. 3 ust. 1 ustawy o systemie informacji w ochronie zdrowia</td>
         <td>Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Zapewnienie zabezpieczenia społecznego oraz zarządzania systemami i usługami zabezpieczenia społecznego, np. wystawianie zaświadczeń lub zwolnień lekarskich</td>
         <td>art. 9 ust. 2 lit h RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta oraz art. 54 ustawy o świadczeniach pieniężnych z ubezpieczenia społecznego w razie choroby i macierzyństwa</td>
         <td>Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Profilaktyka zdrowotna</td>
         <td>art. 9 ust. 2 lit. h RODO w związku z art. 3 ust. 2 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta</td>
         <td>Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Postawienie diagnozy medycznej</td>
         <td>art. 9 ust. 2 lit h) RODO w związku z art. 3 ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta</td>
         <td>Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Realizacja praw pacjenta, w tym prawa do wyznaczenia osoby upoważnionej do udostępnienia jej dokumentacji medycznej lub/i informowania jej o stanie zdrowia oraz udostępniania dokumentacji medycznej lub/i informacji osobie upoważnionej</td>
         <td>art. 6 ust. 1 lit. c RODO w związku z art. 9 ust. h RODO oraz art. 26 ust. 1 ustawy o prawach pacjenta oraz § 8 ust. 1 Rozporządzenia Ministra Zdrowia</td>
         <td>Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Kontakt poprzez wykorzystanie numeru telefonu lub adresu e-mail w celu np. potwierdzenia wizyty, odwołania terminu wizyty, co stanowi prawnie uzasadniony interes ADO, jakim jest obsługa pacjenta</td>
         <td>rt. 6 ust. 1 lit. f RODO</td>
         <td>Okres przechowywania dokumentacji medycznej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Ustalenie, dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej działalności gospodarczej, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
         <td>art. 6 ust. 1 lit. f RODO</td>
         <td>Okres przedawnienia roszczeń wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Prowadzenie ksiąg rachunkowych i dokumentacji podatkowej</td>
         <td>art. 6 ust. 1 lit. c RODO w związku z art. 74 ust. 2 ustawy o rachunkowości oraz innymi przepisami szczególnymi</td>
         <td>Okres przechowywania dokumentacji księgowej i podatkowej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Zapewnienie bezpieczeństwa osób i mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie Stomedica Waszczuk-Dziekan, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
         <td>art. 6 ust. 1 lit. f RODO</td>
         <td>1 miesiąc</td>
      </tr>
   </tbody>
</table>
<p>&nbsp;</p>
<ul>
   <li>Jeśli są Państwo kontrahentami Stomedica Waszczuk-Dziekan</li>
</ul>
<table>
   <tbody>
      <tr>
         <td><strong>Cel przetwarzania danych</strong></td>
         <td><strong>Podstawa prawna przetwarzania danych</strong></td>
         <td><strong>Okres przechowywania/przetwarzana danych osobowych</strong></td>
      </tr>
      <tr>
         <td>Realizacja zawartej z Stomedica Waszczuk-Dziekan umowy</td>
         <td>art. 6 ust. 1 lit. b RODO</td>
         <td>Okres obowiązywania umowy</td>
      </tr>
      <tr>
         <td>Dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej działalności gospodarczej, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
         <td>art. 6 ust. 1 lit. f RODO</td>
         <td>Okres przedawnienia roszczeń wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Prowadzenie ksiąg rachunkowych i dokumentacji podatkowej</td>
         <td>art. 6 ust. 1 lit. c RODO w związku z art. 74 ust. 2 ustawy o rachunkowości oraz innymi przepisami szczególnymi</td>
         <td>Okres przechowywania dokumentacji księgowej i podatkowej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Zapewnienie bezpieczeństwa osób i mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie Stomedica Waszczuk-Dziekan, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
         <td>art. 6 ust. 1 lit. f RODO</td>
         <td>1 miesiąc od czasu zarejestrowania wizerunku</td>
      </tr>
   </tbody>
</table>
<p>&nbsp;</p>
<ul>
   <li>Osoby działające w charakterze przedstawiciela kontrahenta Stomedica Waszczuk-Dziekan, w tym jako osoby wyznaczony do kontaktu, osoby upoważnione do dokonywania określonych działań w imieniu kontrahenta:</li>
</ul>
<table>
   <tbody>
      <tr>
         <td><strong>Cel przetwarzania danych</strong></td>
         <td><strong>Podstawa prawna przetwarzania danych</strong></td>
         <td><strong>Okres przechowywania/przetwarzana danych osobowych</strong></td>
      </tr>
      <tr>
         <td>Realizacja umowy zawartej przez Stomedica Waszczuk-Dziekan z podmiotem, w którego imieniu Osoby występują, co stanowi uzasadniony interes prawny ADO</td>
         <td>art. 6 ust. 1 lit. b, f RODO</td>
         <td>Okres obowiązywania umowy, przez który działają Państwo jako przedstawiciel kontrahenta/ klienta Stomedica Waszczuk-Dziekan</td>
      </tr>
      <tr>
         <td>Dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej działalności gospodarczej, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
         <td>art. 6 ust. 1 lit. f RODO</td>
         <td>Okres przedawnienia roszczeń wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Prowadzenie ksiąg rachunkowych i dokumentacji podatkowej, jeśli Państwa dane były podane w umowie lub innych dokumentach związanych z realizacją przez ADO obowiązków wynikających z przepisów prawa, w szczególności przepisach podatkowych i o rachunkowości</td>
         <td>art. 6 ust. 1 lit. c RODO w związku z art. 74 ust. 2 ustawy o rachunkowości oraz innymi przepisami szczególnymi</td>
         <td>Okres przechowywania dokumentacji księgowej i podatkowej wynikający z przepisów prawa</td>
      </tr>
      <tr>
         <td>Zapewnienie bezpieczeństwa osób i mienia przy wykorzystywaniu monitoringu wizyjnego, rejestrującego wizerunek w siedzibie Stomedica Waszczuk-Dziekan, co stanowi prawnie uzasadniony interes przetwarzania danych przez ADO</td>
         <td>art. 6 ust. 1 lit. f RODO</td>
         <td>1 miesiąc</td>
      </tr>
   </tbody>
</table>
<p>&nbsp;</p>
<ul>
   <li>Osoby korzystające z formularzy kontaktowych dostępnych na stronach internetowych prowadzonych przez ADO:</li>
</ul>
<p>&nbsp;</p>
<table>
   <tbody>
      <tr>
         <td><strong>Cel przetwarzania danych</strong></td>
         <td><strong>Podstawa prawna przetwarzania danych</strong></td>
         <td><strong>Okres przechowywania/przetwarzana danych osobowych</strong></td>
      </tr>
      <tr>
         <td>Kontakt w związku z pytaniem/ zgłoszeniem złożonym za pośrednictwem formularza kontaktowego, na podstawie udzielonej zgody na przetwarzanie danych</td>
         <td>art. 6 ust. 1 lit. a RODO</td>
         <td>Przez okres niezbędny do odpowiedzi na zgłoszenie i prowadzenia korespondencji/ rozmów związanych ze zgłoszeniem, jednak nie dłużej niż do wycofania przez Panią/Pana zgody na przetwarzanie danych osobowych</td>
      </tr>
      <tr>
         <td>Dochodzenia roszczeń i obrony przed roszczeniami związanymi z prowadzoną przez ADO działalnością gospodarczą, co stanowi uzasadniony interes prawny ADO, gdy Państwa zgłoszenie może uzasadniać przetwarzanie danych w tym celu (np. gdy zgłoszenie dotyczy reklamacji)</td>
         <td>art. 6 ust. 1 lit f RODO</td>
         <td>Przez okres przedawnienia roszczeń określony w przepisach prawa</td>
      </tr>
   </tbody>
</table>
<p>&nbsp;</p>
<ol start="5">
   <li><strong>Jakie jest źródło danych pozyskiwanych przez Stomedica Waszczuk-Dziekan? </strong></li>
</ol>
<table>
   <tbody>
      <tr>
         <td><strong>Pacjenci:</strong><br /> Korzystanie z usług medycznych świadczonych przez Stomedica Waszczuk-Dziekan</td>
         <td>Dane pozyskiwane są bezpośrednio od osoby lub osoby upoważnionej podczas rezerwacji wizyty oraz wykonywania na ich rzecz usługi medycznej, m.in. konsultacji stomatologicznej, badania, zabiegu. Rezerwacja wizyty może odbywać się bezpośrednio, za pośrednictwem telefonu lub formularza online</td>
      </tr>
      <tr>
         <td><strong>Klienci:</strong><br /> Dokonywanie zakupów</td>
         <td>Dane pozyskiwane są bezpośrednio przez osobę zawierającą umowę, przed zawarciem umowy lub w trakcie jej realizacji.</td>
      </tr>
      <tr>
         <td><strong>Kontrahenci:</strong><br /> Nawiązywanie relacji handlowych</td>
         <td>Dane pozyskiwane są bezpośrednio od podmiotu przed zawarciem umowy lub w trakcie jej realizacji.</td>
      </tr>
      <tr>
         <td><strong>Przedstawiciele kontrahenta, osoby do kontaktu</strong></td>
         <td>Dane pozyskiwane są od podmiotu, w imieniu którego występują lub bezpośrednio od przedstawiciela kontrahenta. W przypadku gdy dane osobowe podawane są przez podmiot, w imieniu którego występują, ADO zbiera dane takie jak: imię, nazwisko, numer telefonu, adres e-mail, stanowisko służbowe.</td>
      </tr>
      <tr>
         <td>Użytkownicy formularzy kontaktowych</td>
         <td>Dane pozyskiwane są bezpośrednio w formularzu kontaktowym</td>
      </tr>
   </tbody>
</table>
<p><strong>&nbsp;</strong></p>
<ol start="6">
   <li><strong>Czy podanie swoich danych jest konieczne? </strong></li>
</ol>
<table>
   <tbody>
      <tr>
         <td><strong>Pacjenci:</strong><br /> Korzystanie z usług medycznych świadczonych przez Stomedica Waszczuk-Dziekan</td>
         <td>Podanie danych osobowych jest dobrowolne, jednak stanowi warunek ustawowy w zakresie prowadzenia przez ADO dokumentacji, w tym dokumentacji medycznej w sposób określony przepisami prawa, w tym do identyfikacji Państwa tożsamości. W związku z powyższym niepodanie danych może skutkować odmową rezerwacji wizyty, rejestracji albo odmową udzielenia świadczenia zdrowotnego – odmowa podania danych uniemożliwi udzielanie świadczeń zdrowotnych przez ADO. W przypadku niepodania numeru telefonu i adresu e-mail ADO nie może odmówić udzielenia Państwu świadczenia zdrowotnego, jednak podanie tych danych ułatwia korzystanie ze świadczeń dzięki możliwości kontaktowania się w sprawie realizacji usług, w tym potwierdzenia i odwołania wizyty.</td>
      </tr>
      <tr>
         <td><strong>Klienci:</strong><br /> Dokonywanie zakupów</td>
         <td>Podanie danych niezbędnych do realizacji umowy sprzedaży jest warunkiem zawarcia umowy sprzedaży. Odmowa ich podania będzie skutkować brakiem możliwości dokonania zakupu lub wystawienia faktury.</td>
      </tr>
      <tr>
         <td><strong>Kontrahenci:</strong><br /> Nawiązywanie relacji handlowych</td>
         <td>Podanie danych niezbędnych do złożenia oferty, a następnie podpisania i realizacji umowy, jest warunkiem zawarcia umowy. Odmowa ich podania skutkować będzie brakiem możliwości złożenia oferty oraz zawarcia umowy.</td>
      </tr>
      <tr>
         <td><strong>Przedstawiciele kontrahenta, osoby do kontaktu</strong></td>
         <td>Podanie danych bezpośrednio do &nbsp;o ADO, jest dobrowolne, ale potrzebne do realizacji przez ADO umowy na rzecz podmiotu, w imieniu którego się występuje. W przypadku niepodania danych realizacja umowy może być utrudniona.</td>
      </tr>
      <tr>
         <td><strong>Użytkownicy formularzy kontaktowych</strong></td>
         <td>Podanie danych niezbędnych do udzielenia i odpowiedzi na zgłoszenie/ pytania jest warunkiem uzyskania odpowiedzi. Brak podania danych skutkować będzie brakiem możliwości udzielenia odpowiedzi na zgłoszenie/ pytanie.</td>
      </tr>
   </tbody>
</table>
<p><strong>&nbsp;</strong></p>
<ol start="7">
   <li><strong>Komu Stomedica Waszczuk-Dziekan może przekazać państwa dane osobowe?</strong></li>
</ol>
<p>Dostęp do danych osobowych mogą mieć:</p>
<ol>
   <li>pracownicy i współpracownicy ADO upoważnieni do przetwarzania Państwa danych osobowych na polecenie ADO;</li>
   <li>w przypadku danych osobowych przetwarzanych w celu świadczenia usług medycznych – inne podmioty lecznicze w celu zapewnienia ciągłości leczenia oraz dostępności świadczeń zdrowotnych;</li>
   <li>
      podmioty, którym ADO powierzył przetwarzanie danych osobowych, w tym:
      <ul>
         <li>dostawcy usług technicznych i organizacyjnych (w szczególności dostawcy usług teleinformatycznych, dostawcy sprzętu medycznego, podmioty świadczące usługi pocztowe i kurierskie);</li>
         <li>dostawcy usług prawnych i doradczych, w tym w przypadku dochodzenia roszczeń związanych z prowadzoną przez Stomedica Waszczuk-Dziekan działalnością gospodarczą i obrony przed roszczeniami;</li>
         <li>zleceniodawcy, z którymi ADO zawarł umowy na świadczenie usług medycznych;</li>
         <li>inne podmioty, osoby lub organy – w zakresie i na zasadach określonych przepisami prawa, w tym osoby upoważnione przez Państwa w ramach realizacji praw pacjenta; z zastrzeżeniem, że udostępnienie danych ww. podmiotom odbywa się w zgodzie z obowiązującymi przepisami prawa oraz przy zachowaniu pełnych zasad związanych z ich bezpieczeństwem.</li>
      </ul>
   </li>
</ol>
<p>&nbsp;</p>
<ol start="8">
   <li><strong>Czy dane będą przekazywane do państw spoza europejskiego obszaru gospodarczego (do&nbsp;krajów innych niż kraje unii europejskiej oraz Islandia, Norwegia i Liechtenstein)?</strong></li>
</ol>
<p>ADO może przekazywać Państwa dane osobowe do państw spoza Europejskiego Obszaru Gospodarczego (dalej jako Państwa trzecie) i organizacji międzynarodowych, co jest związane z zakresem prowadzonej przez Stomedica Waszczuk-Dziekan działalności oraz podejmowanej z różnymi podmiotami współpracy. Stomedica Waszczuk-Dziekan zapewnia, że dane będą przekazywane do państw, wobec których Komisja Europejska wydała decyzje o spełnianiu przez nie odpowiedniego stopnia ochrony danych osobowych i/lub z zachowaniem wszelkich wymogów prawnych, w tym na podstawie stosownej umowy, zawierającej klauzule ochrony danych przyjęte przez Komisję Europejską, zapewniając odpowiedni sposób zabezpieczenia przekazywanych danych osobowych.</p>
<ol start="9">
   <li><strong>Jakie prawa przysługują w związku z przetwarzaniem danych przez Stomedica Waszczuk-Dziekan? </strong></li>
</ol>
<p>W związku z przetwarzaniem przez Stomedica Waszczuk-Dziekan danych osobowych przysługuje Państwu:</p>
<ol>
   <li>prawo dostępu do Państwa danych osobowych (art. 15 RODO);</li>
   <li>prawo do sprostowania Państwa danych osobowych (art. 16 RODO);</li>
   <li>prawo do żądania usunięcia danych w przypadkach określonych w art. 17 ust. 1, z uwzględnieniem wyjątków określonych w przepisie art. 17 ust. 3 RODO;</li>
   <li>prawo do żądania ograniczenia przetwarzania danych w przypadkach określonych w art. 18 RODO;</li>
   <li>prawo do przenoszenia danych w przypadkach określonych w przepisach art. 20 RODO.</li>
</ol>
<p>Jeżeli chcą Państwo skorzystać z któregokolwiek z tych uprawnień, Stomedica Waszczuk-Dziekan umożliwia kontakt:</p>
<ol>
   <li>za pomocą e-maila: recepcja@stomedica.pl</li>
   <li>drogą pocztową: ul. Żółkiewskiego 1A, 78-100 Kołobrzeg,</li>
</ol>
<p>Przysługuje Państwu również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych w przypadku, jeśli przy przetwarzaniu Państwa danych osobowych Stomedica Waszczuk-Dziekan naruszy przepisy dotyczące ochrony danych osobowych.</p>
<p><strong>PRAWO DO WYCOFANIA ZGODY NA PRZETWARZANIE DANYCH OSOBOWYCH </strong></p>
<p>Jeśli ADO przetwarza Państwa dane osobowe na podstawie zgody, przysługuje Państwu prawo wycofania zgody w dowolnym momencie, bez wpływu na ważność przetwarzania, które zostało dokonane na podstawie zgody przed jej wycofaniem.</p>
<p>Mogą Państwo wycofać zgodę:</p>
<ol>
   <li>za pomocą e-maila: recepcja@stomedica.pl;</li>
   <li>w recepcji Stomedica Waszczuk-Dziekan,</li>
   <li>drogą pocztową: ul. Żółkiewskiego 1A, 78-100 Kołobrzeg,</li>
</ol>
<p><strong>&nbsp;</strong></p>
<ol start="11">
   <li><strong>Czy wobec danych osobowych będą podejmowane zautomatyzowane decyzje (decyzje bez udziału człowieka)? </strong></li>
</ol>
<p>Wobec danych osobowych przetwarzanych przez Stomedica Waszczuk-Dziekan nie będą podejmowane zautomatyzowane decyzje (decyzje bez udziału człowieka), w tym dane nie będą podlegały zautomatyzowanemu profilowaniu.</p>
<p>Wyjątkiem od powyższego są decyzje związane z wykonywaniem wiążącej nas z Państwem umowy o udzielanie świadczeń zdrowotnych, np. decyzje dotyczące zapewnienia Państwu odpowiedniej dostępności usług, których podejmowanie w taki sposób jest niezbędne do zawarcia lub wykonania już zawartej umowy.</p>
<ol start="12">
   <li><strong>Prawo wniesienia sprzeciwu</strong></li>
</ol>
<p>Stomedica Waszczuk-Dziekan informuje, że z przyczyn związanych ze szczególną sytuacją przysługuje pacjentom/klientom/kontrahentom prawo do wniesienia sprzeciwu wobec przetwarzania ich danych osobowych, w tym profilowania, w przypadku gdy podstawą przetwarzania danych przez ADO jest:</p>
<ol>
   <li>niezbędność przetwarzania do wykonania zadania realizowanego w interesie publicznym lub w ramach sprawowania władzy publicznej powierzonej administratorowi danych (art. 6 ust. 1 lit. e RODO),</li>
   <li>uzasadniony interes prawny ADO (art. 6 ust. 1 lit. f RODO).</li>
</ol>
<p>Po złożeniu sprzeciwu Stomedica Waszczuk-Dziekan nie będzie mogła przetwarzać danych osobowych opierając się na wyżej wymienionych podstawach przetwarzania, chyba że wykaże istnienie ważnych, prawnie uzasadnionych podstaw do przetwarzania danych, nadrzędnych wobec interesów, praw i wolności osoby, której dane dotyczą, lub podstaw do ustalenia, dochodzenia i obrony roszczeń.</p>
<p>Jeżeli Stomedica Waszczuk-Dziekan przetwarza dane osobowe pacjentów/klientów na potrzeby marketingu bezpośredniego, przysługuje im prawo do wniesienia sprzeciwu wobec przetwarzania tych danych przez Stomedica Waszczuk-Dziekan w celach marketingu bezpośredniego, w tym profilowania.</p>
<ol start="13">
   <li><strong>Bezpieczeństwo danych osobowych</strong></li>
</ol>
<p>Stomedica Waszczuk-Dziekan stosuje odpowiednie środki techniczne i organizacyjne by przetwarzane dane osobowe były bezpieczne, w tym zabezpiecza dane osobowe przed nieuprawnionym dostępem, ich utratą lub zniszczeniem. W celu zapewnienia bezpieczeństwa tych danych osobowych w Stomedica Waszczuk-Dziekan zostały wdrożone odpowiednie zabezpieczenia personalne, organizacyjne, techniczne (informatyczne) i fizyczne.</p>
    </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  components: {
  },
  data: function () {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
ol, ul {
  padding-left: 20px;
}
p {
  padding-left: 15px;
}
h1 {
  color: rgb(41, 41, 41);
}
a {
  text-decoration: none;
  color: rgb(19, 156, 127);
}
table, th, td {
  border: 1px solid;
}
td {
  padding: 5px;
}
table {
  border-collapse: collapse;
  border: solid 1px black;
}
</style>
