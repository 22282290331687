import { createApp } from 'vue'
import App from './App.vue'
import { library } from "@fortawesome/fontawesome-svg-core";
// import { faPhone, faInstagram } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// library.add(faPhone);
// library.add(faInstagram);
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();
// import(/* webpackChunkName: "mainStyle" */ '../scss/variables.scss')

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");