<template>
    <div class="service_container">
        <h2>Chirurgia Stomatologiczna</h2>
        <p>Wykonujemy wszystkie zabiegi z zakresu chirurgii stomatologicznej - ekstrakcje zębów, 
          chirurgiczne usuwanie zębów, usuwanie zębów zatrzymanych, usuwanie zębów mądrości(ósemek), 
          resekcje wierzchołka, plastykę dziąseł, plastykę wędzidełka wargi i języka, wyłuszczenie torbieli, oraz pokrywanie recesji dziąseł.</p>
        <p>Wszystkie zabiegi wykonujemy przy użyciu nowoczesnego sprzętu, w miejscowym znieczuleniu, dzięki czemu pacjent czuje się bezpiecznie i komfortowo.</p>
        <p>Aby zniwelować stres związany z wizytą, leczenie możemy przeprowadzić w sedacji wziewnej (przy użyciu gazu rozweselającego).</p>
        <video controls>
          <source :src="require('../../assets/videos/chirurgia.mp4')" @load="test" type="video/mp4">
        Twoja przeglądarka nie obsługuje video.
        </video>   
    </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    prev() {
      this.$parent.$parent.prev()
    },
    next() {
      this.$parent.$parent.next()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>