<template>
  <div class="card">
    <div class="face" :class="{ front: back, '': 'simple' }">
        <img :src="require('../../assets/portait/' + photoPath)" alt="photo placeholder"/>
        <h2>{{fullName}}</h2>
        <p> {{profesion}} </p>
    </div>
    <div v-if="back" class="face back">
        <p>{{description}}</p>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Member Card',
  props: {
      fullName: {
          type: String,
          require: true
      },
      photoPath: {
          type: String,
          require: true
      },
      profesion: {
          type: String,
          require: true
      },
      description: {
          type: String,
          require: true
      },
      back: {
          type: Boolean,
          default: false
      }
  },
  data: function () {
    return {
    }
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

.card {
    width: 300px;
    position: relative;
    height: 400px;
    transform-style: preserve-3d;
    margin-bottom: 21px;
    .face {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // transform-style: preserve-3d;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: transform 1s ease-in-out;
        transform: perspective(900px) rotateY(0deg);
        border-radius: 15px;
        backface-visibility: hidden;
        box-sizing: border-box;
        padding: 0 20px;
        h2 {
            font-size: 20px;
        }
        p{
            margin: 0;
            color: rgb(83, 81, 81);
            font-size: 14px !important;
        }
        img {
            width: 100%;
            height: 100%;
            max-height: 300px;
            object-fit: cover;
            object-position: 50% 20%;
            display: block;
            border-radius: 15px;
        }
    }

    .face.front {
        background-color: rgb(209,209,209);
    }

    .face.simple {
        background-color: rgb(209,209,209);
        border-radius: 15px;
    }

    .face.back {
        background-color: #cae0eb;
        transform: perspective(900px) rotateY(180deg);
        z-index: -9;
    }

    &:hover {
        .face.front {
            transform: perspective(900px) rotateY(180deg);
        }
        .face.back {
            transform: perspective(900px) rotateY(360deg);
        }
    }
}

</style>
